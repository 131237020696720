import React, { useState } from "react";
import Modal from "react-modal";
import { acceptAnswer } from "@api/api";

const AcceptButton = ({ answerId }) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);

  const closeAcceptModal = () => {
    setIsAcceptModalOpen(false);
  };

  const openAcceptModal = () => {
    setIsAcceptModalOpen(true);
  };

  const handleAccept = async () => {
    try {
      await acceptAnswer(answerId);
      closeAcceptModal();
      alert("답변이 채택되었습니다.");
      window.location.reload();
    } catch (e) {
      alert("답변 채택에 실패했습니다.");
    }
  };

  return (
    <>
      <button
        onClick={openAcceptModal}
        className="ml-3 border border-thirdText px-3 rounded-full hover:bg-green-600 hover:border-green-600 py-0.5 hover:text-textColor"
      >
        답변 채택
      </button>
      {isAcceptModalOpen && (
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onRequestClose={closeAcceptModal}
          onConfirm={handleAccept}
        />
      )}
    </>
  );
};

const AcceptModal = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="py-7 px-10 bg-modalBg rounded-lg shadow-lg max-w-md mx-auto my-20"
      overlayClassName="fixed bg-black inset-0 bg-black bg-opacity-50 flex items-center"
    >
      <>
        <h2 className="text-[18px] font-bold mb-6">답변채택</h2>
        <div className="flex justify-start py-3 w-72 mb-6">
          해당 답변을 채택하겠습니까?
          <br /> ( 답변 채택 시 롤백은 불가합니다. )
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onRequestClose}
            className="px-5 py-2 text-secondText rounded mr-2"
          >
            취소
          </button>
          <button
            onClick={onConfirm}
            className="bg-green-600 px-5 py-2 rounded"
          >
            답변 채택
          </button>
        </div>
      </>
    </Modal>
  );
};

export default AcceptButton;
