import React, { useEffect, useState } from "react";
import { getTechNoteComments, postTechNoteComment } from "@api/api";
import { TextEditor } from "@components";
import TechNoteCommentItem from "./TechNoteCommentItem";

const TechNoteComments = ({ techNoteId, techNoteUserId }) => {
  const [techNoteComments, setTechNoteComments] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // eslint-disable-next-line
  const [textLength, setTextLength] = useState(0);
  const [answerContent, setAnswerContent] = useState("");
  const [sortOrder, setSortOrder] = useState("bydate");

  useEffect(() => {
    const fetchAnswers = async () => {
      await refreshTechNoteComments();
    };
    fetchAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techNoteId, sortOrder]);

  const refreshTechNoteComments = async () => {
    try {
      const response = await getTechNoteComments(techNoteId, sortOrder);
      setTechNoteComments(response);
    } catch (error) {
      console.error("코멘트 목록 갱신에 실패했습니다.", error);
    }
  };

  const handleEditorChange = (content, length) => {
    setAnswerContent(content);
    setTextLength(length);
    setIsButtonDisabled(length < 5);
  };

  const handleAnswerSubmit = async () => {
    try {
      if (answerContent.length < 5) {
        alert("코멘트는 최소 5자 이상이어야 합니다.");
        return;
      }
      await postTechNoteComment(techNoteId, answerContent);
      alert("코멘트 작성이 완료되었습니다.");
      setAnswerContent("");

      await refreshTechNoteComments(techNoteId);
    } catch (error) {
      console.error("코멘트 제출에 실패했습니다.", error);
    }
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
  };

  return (
    <>
      {techNoteComments.length > 0 && (
        <div>
          <div className="flex mb-5 justify-between px-6 sm:px-14 items-center mt-20">
            <h2>총 {techNoteComments.length}개의 코멘트</h2>
            <div className="flex">
              <span className="font-bold mr-5 mt-1 text-thirdText">
                ORDER BY{" "}
              </span>
              <button
                className={`py-1 mr-2 px-3 rounded-lg ${
                  sortOrder === "bydate" ? "bg-grayBox" : "hover:bg-deepGrayBox"
                } `}
                onClick={() => handleSortOrderChange("bydate")}
              >
                날짜순
              </button>
              <button
                className={`py-0.5 px-3 rounded-lg ${
                  sortOrder === "popular"
                    ? "bg-grayBox"
                    : "hover:bg-deepGrayBox"
                } `}
                onClick={() => handleSortOrderChange("popular")}
              >
                인기순
              </button>
            </div>
          </div>
          <hr className="border-hrColor mt-2 mb-5" />
          {techNoteComments.map((techNoteComment) => (
            <TechNoteCommentItem
              key={techNoteComment.id}
              {...techNoteComment}
              refreshTechNoteComments={refreshTechNoteComments}
              techNoteUserId={techNoteUserId}
            />
          ))}
        </div>
      )}
      <div className="mb-20 px-6 sm:px-14">
        <div className="mt-20">
          <div className="mb-5 text-[17px]">나의 코멘트</div>
          <TextEditor
            value={answerContent}
            onChange={handleEditorChange}
            placeholder="최소 5자 이상 작성이 필요합니다."
          />
          <span className="text-red-400 text-[12px] mt-2">
            * 타인을 비방 또는 불쾌한 내용이 포함 될 경우 사전 고지 없이 삭제 될
            수 있습니다.
          </span>
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleAnswerSubmit}
            disabled={isButtonDisabled}
            className={`mt-8 ${
              isButtonDisabled ? "text-disableTextColor cursor-not-allowed" : ""
            }`}
          >
            작성 완료
          </button>
        </div>
      </div>
    </>
  );
};

export default TechNoteComments;
