import React, { useEffect, useState } from "react";

const UserProfileImage = ({ profilePicture, imageStyle }) => {
  const [imgSrc, setImgSrc] = useState(profilePicture);

  useEffect(() => {
    setImgSrc(profilePicture);
  }, [profilePicture]);

  const handleError = () => {
    setImgSrc(process.env.REACT_APP_DEFAULT_PROFILE_URL);
  };

  return (
    <img
      src={imgSrc}
      alt="Profile"
      className={imageStyle}
      onError={handleError}
    />
  );
};

export default UserProfileImage;
