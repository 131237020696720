import React, { useEffect, useState } from "react";
import { FaGithub, FaLink, FaBookOpen } from "react-icons/fa";
import { HiCircleStack } from "react-icons/hi2";
import { IoBusiness } from "react-icons/io5";
import {
  checkNicknameDuplication,
  getUserData,
  updateUserData,
  uploadProfileImage,
  deleteProfileImage,
} from "@api/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { LoadingPage } from "@pages";
import { WithdrawModal } from "@components/modal";
import defaultProfile from "@assets/defaultProfile.png";
import { ServerErrorPage } from "./error";
import ProfileImageModal from "@components/modal/ProfileImageModal";
import { cleanAndReplaceSpaces } from "@utils/utils";

const ProfileSettingPage = () => {
  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [about, setAbout] = useState("");
  const [techStack, setTechStack] = useState("");
  const [isEmailConsent, setIsEmailConsent] = useState(false);
  const [githubUrl, setGithubUrl] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [book, setBook] = useState("");
  const [company, setCompany] = useState("");
  const [duplicateNickname, setDuplicateNickname] = useState(false);
  const [isCalledNicknameAPI, setIsCalledNicknameAPI] = useState(false);
  const [currentProfileImage, setCurrentProfileImage] = useState(null);
  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [isProfileImageModalOpen, setProfileImageModalOpen] = useState(false); // 프로필 이미지 모달 상태 추가
  const [hasServerError, setHasServerError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleToggle = () => {
    setIsEmailConsent(!isEmailConsent);
  };

  const fetchUserData = async () => {
    try {
      const user = await getUserData();

      setUserProfile(user);

      setIsEmailConsent(user.isEmailConsent);
      setEmail(user.email);
      setNickname(user.nickname);
      setGithubUrl(user.githubUrl);
      setWebsiteUrl(user.websiteUrl);
      setAbout(user.about);
      setIsEmailConsent(user.isEmailConsent);
      setCurrentProfileImage(user.profilePicture);
      setBook(user.book);
      setCompany(user.company);
      setTechStack(user.techStack);
      setHasServerError(false);
    } catch (e) {
      console.error("유저 정보를 불러오는데 실패했습니다.", e);
      setHasServerError(true);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleUpdateUserData = async (e) => {
    e.preventDefault();
    if (!duplicateNickname && userProfile.nickname !== nickname) {
      alert("닉네임 중복체크가 필요합니다.");
      return;
    }

    if (nickname.length < 3) {
      alert("닉네임 최소 3자 입니다.");
      return;
    }

    try {
      await updateUserData({
        nickname,
        isEmailConsent,
        about,
        githubUrl,
        websiteUrl,
        book,
        company,
        techStack,
      });
      alert("수정이 완료되었습니다.");
      navigate(
        `/users/${userProfile.id}/${cleanAndReplaceSpaces(
          userProfile.nickname
        )}?tab=dashboard`
      );
    } catch (e) {
      console.error("유저 업데이트를 실패했습니다", e);
    }
  };

  const handleError = () => {
    setCurrentProfileImage(defaultProfile);
  };

  const handleNicknameEdit = (e) => {
    const trimmedValue = e.target.value.replace(/\s/g, ""); // 모든 공백 제거
    if (trimmedValue.length <= 15) setNickname(trimmedValue.replace(/\s/g, ""));
    setIsCalledNicknameAPI(false);
    setDuplicateNickname(false);
  };

  const handleNicknameDuplication = async (e) => {
    e.preventDefault();
    if (nickname.length < 3) {
      alert("닉네임은 최소 3자 이상입니다.");
      return;
    }
    const response = await checkNicknameDuplication(nickname);
    setDuplicateNickname(response);
    setIsCalledNicknameAPI(true);
  };

  const handleImageChange = async (file) => {
    try {
      const options = {
        maxSizeMB: 1, // 최대 파일 크기를 1MB로 설정
        maxWidthOrHeight: 150, // 최대 너비 또는 높이를 150px로 설정
        useWebWorker: true, // 웹 워커 사용
      };

      // 이미지 압축
      const compressedFile = await imageCompression(file, options);

      await uploadProfileImage(compressedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        setCurrentProfileImage(e.target.result);
      };
      reader.readAsDataURL(compressedFile);
    } catch (e) {
      console.error("사진 업로드에 문제가 발생했습니다.", e);
    }
  };

  const handleDeleteImage = async () => {
    try {
      await deleteProfileImage();
      setCurrentProfileImage(null);
      alert("프로필 이미지가 삭제되었습니다.");
    } catch (e) {
      console.error("이미지 삭제에 문제가 발생했습니다.", e);
    }
  };

  if (hasServerError) return <ServerErrorPage />;

  if (!userProfile) return <LoadingPage />;

  return (
    <div className="mb-10">
      <div className="ml-10p py-16 text-[24px] mb-5 font-bold">프로필 수정</div>
      <div className="mb-5 mx-5 md:mx-10p 2xl:mx-15p">
        <div className="flex">
          <div className="w-30p mr-5 2xl:mr-10 ">
            <img
              src={currentProfileImage}
              alt="Profile"
              className="w-28 h-28 rounded-lg cursor-pointer"
              onError={handleError}
              onClick={() => setProfileImageModalOpen(true)}
            />
          </div>
          <div className="w-full">
            <div className="text-sm mb-5">
              <div className="mb-1">이메일</div>
              <div className="py-2.5 px-3 w-80 bg-boxColor text-disableTextColor rounded-lg ">
                {email}
              </div>
            </div>
            <div className="text-sm">
              <div className="mb-1">
                닉네임
                <span className="text-thirdText text-[12px] ml-1">
                  최소 5자 ({nickname.length} / 15자)
                </span>
              </div>
              <input
                type="text"
                id="nickname"
                value={nickname}
                onChange={handleNicknameEdit}
                className="py-2.5 px-3 w-56 bg-boxColor rounded-lg"
              />
              <button
                className="px-3 hover:text-linkColor py-2"
                onClick={handleNicknameDuplication}
              >
                중복확인
              </button>
            </div>
            {isCalledNicknameAPI && (
              <>
                {duplicateNickname && (
                  <div className="ml-2 mt-1  text-green-500">
                    사용가능한 닉네임 입니다.
                  </div>
                )}
                {nickname !== userProfile.nickname && !duplicateNickname && (
                  <div className="ml-2 mt-1  text-red-500">
                    중복되는 닉네임 입니다.
                  </div>
                )}
              </>
            )}

            <div className="text-sm mt-5 mb-5">
              <div className="mb-1">
                소개
                <span className="text-thirdText text-[12px] ml-1">
                  ({about.length} / 500자)
                </span>
              </div>

              <textarea
                type="text"
                id="about"
                value={about}
                onChange={(e) => {
                  if (e.target.value.length <= 500) setAbout(e.target.value);
                }}
                placeholder="개발자에 대한 자신의 이야기를 해주세요"
                className="py-2.5 px-3 w-full bg-boxColor rounded-lg placeholder-thirdText"
              />
            </div>
            <div className="text-secondText mb-2">Link</div>
            <div className="flex items-center mb-3">
              <FaGithub className="w-6 h-6 mr-4" />
              <input
                type="text"
                id="githubUrl"
                value={githubUrl}
                onChange={(e) => setGithubUrl(e.target.value)}
                placeholder="https://"
                className="py-2.5 px-3 w-full bg-boxColor rounded-lg placeholder-thirdText"
              />
            </div>
            <div className="flex items-center">
              <FaLink className="w-6 h-6 mr-4" />
              <input
                type="text"
                id="websiteUrl"
                value={websiteUrl}
                placeholder="https://"
                onChange={(e) => setWebsiteUrl(e.target.value)}
                className="py-2.5 px-3 w-full bg-boxColor rounded-lg placeholder-thirdText"
              />
            </div>
            <div className="text-secondText mt-5 mb-2">Book</div>
            <div className="flex items-center mb-3">
              <FaBookOpen className="w-6 h-6 mr-4" />
              <input
                type="text"
                id="book"
                value={book}
                onChange={(e) => setBook(e.target.value)}
                placeholder="인상깊었던 개발서적을 입력해주세요"
                className="py-2.5 px-3 w-full bg-boxColor rounded-lg placeholder-thirdText"
              />
            </div>
            <div className="text-secondText mt-5 mb-2">Company</div>
            <div className="flex items-center mb-3">
              <IoBusiness className="w-6 h-6 mr-4" />
              <input
                type="text"
                id="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="현재 소속을 입력해주세요"
                className="py-2.5 px-3 w-full bg-boxColor rounded-lg placeholder-thirdText"
              />
            </div>
            <div className="text-secondText mt-5 mb-2">Tech Stack</div>
            <div className="flex items-center mb-3">
              <HiCircleStack className="w-7 h-6 mr-4" />
              <input
                type="text"
                id="techStack"
                value={techStack}
                onChange={(e) => setTechStack(e.target.value)}
                placeholder="많이 사용하는 기술스택을 적어주세요 (ex. Java, React)"
                className="py-2.5 px-3 w-full bg-boxColor rounded-lg placeholder-thirdText"
              />
            </div>
            <div className="flex items-center justify-between mt-10 mb-10">
              <label className="text-sm font-medium">이메일 수신 동의</label>
              <div
                className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
                  isEmailConsent ? "bg-green-500" : ""
                }`}
                onClick={handleToggle}
              >
                <div
                  className={`bg-white w-5 h-5 rounded-full shadow-md transform ${
                    isEmailConsent ? "translate-x-5" : ""
                  }`}
                />
              </div>
            </div>
            {/* <div className="text-[10px] text-thirdText  mb-10">
              * 이메일은 꼭 전달해야 할 메일만 전송합니다.
            </div> */}
            <div className="flex justify-between mb-20">
              <button
                onClick={() => setWithdrawModalOpen(true)}
                className="py-1.5 text-[13px] text-thirdText hover:text-textColor"
              >
                회원 탈퇴
              </button>

              <div>
                <Link
                  to={`/users/${userProfile.id}/${cleanAndReplaceSpaces(
                    userProfile.nickname
                  )}?tab=dashboard`}
                  className="py-1.5 text-secondText hover:text-textColor"
                >
                  뒤로가기
                </Link>
                <button
                  onClick={handleUpdateUserData}
                  className="py-1.5 scroll-px-2 ml-10 text-linkColor hover:font-bold"
                >
                  수정
                </button>
              </div>
            </div>
          </div>
        </div>
        <WithdrawModal
          isOpen={isWithdrawModalOpen}
          onClose={() => setWithdrawModalOpen(false)}
        />
        <ProfileImageModal
          isOpen={isProfileImageModalOpen}
          onClose={() => setProfileImageModalOpen(false)}
          onImageChange={handleImageChange}
          onDeleteImage={handleDeleteImage}
        />
      </div>
    </div>
  );
};

export default ProfileSettingPage;
