import React from "react";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { IoChatboxEllipses } from "react-icons/io5";
import { stripHtmlTags, cleanAndReplaceSpaces } from "@utils/utils";
import UserProfileImage from "@components/common/UserProfileImage";

const TechNoteItem = ({
  id,
  title,
  body,
  viewsCount,
  lastUpdated,
  profilePicture,
  nickname,
  userId,
  commentCount,
  voteCount,
}) => {
  return (
    <div className="flex px-6 py-2.5 md:px-8">
      <div className="flex border border-hrColor py-2.5 px-4 rounded-xl">
        <div className="mt-1 mr-1">
          <span className="inline-flex items-center py-0.5 px-2 border rounded-lg border-starBorder">
            <FaStar className="text-yellow-500 h-3 w-3" />
            <span className="ml-1 text-yellow-500 text-[12px]">
              {voteCount}
            </span>
          </span>
          <span className="inline-flex items-center mt-2 py-0.5 px-2 border rounded-lg border-starBorder">
            <IoChatboxEllipses className="text-secondText h-3 w-3" />
            <span className="ml-1 text-secondText text-[12px]">
              {commentCount}
            </span>
          </span>
        </div>
        <div className="w-full">
          <Link
            to={`/technote/${id}/${cleanAndReplaceSpaces(title)}`}
            className="text-inherit no-underline"
          >
            <div className="flex mb-1 flex-wrap items-center">
              <h1 className="md:text-[16px] mb-0.5 font-semibold text-linkColor inline-block align-middle hover:text-blue-500">
                {title}
              </h1>
            </div>
            <p className="hidden text-[13px] md:block">
              {stripHtmlTags(body, 120)}
            </p>
            <p className="block text-[12px] md:hidden">
              {stripHtmlTags(body, 100)}
            </p>
          </Link>
          <div className="flex items-center mt-2 text-secondText text-[11px]">
            <Link
              to={`/users/${userId}/${cleanAndReplaceSpaces(
                nickname
              )}?tab=dashboard`}
              className="flex mr-2"
            >
              <UserProfileImage
                profilePicture={profilePicture}
                imageStyle={"w-4 h-4 rounded-full mr-1"}
              />
              <div className="text-linkColor font-medium">{nickname}</div>
            </Link>
            <div className=" border-l-2 border-gray-400 h-2.5 mr-2"></div>
            <div className="mr-2">마지막 작성일. {lastUpdated}</div>
            <div className="border-l-2 border-gray-400 h-2.5 mr-2"></div>
            <div>조회수. {viewsCount}</div>
          </div>
          <div className="flex items-center mt-2"></div>
        </div>
      </div>
    </div>
  );
};

export default TechNoteItem;
