import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SlNote } from "react-icons/sl";
import { getQuestions } from "@api/api";
import { Pagination, QuestionItem } from "@components";
import LoadingPage from "./LoadingPage";
import { ServerErrorPage } from "@pages";
import { useAuth } from "@contexts/AuthContext";
import { LoginModal } from "@components/modal";

const QnAListPage = () => {
  const [questions, setQuestions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [QuestionsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("latest");
  const [hasServerError, setHasServerError] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, user } = useAuth();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentPage = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    if (!queryParams.has("page")) {
      navigate("?page=1", { replace: true });
    }
  }, [queryParams, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchQuestions = async () => {
      setIsLoading(true); // 로딩 상태 설정
      try {
        const response = await getQuestions(
          sortOrder,
          currentPage,
          QuestionsPerPage
        );
        setQuestions(response.questions);
        setTotalCount(response.totalCount);
        setHasServerError(false);
      } catch (error) {
        console.error("질문 데이터를 불러오는데 실패했습니다.", error);
        setHasServerError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchQuestions();
  }, [sortOrder, currentPage, QuestionsPerPage]);

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    paginate(1);
  };

  const paginate = (pageNumber) => {
    navigate(`?page=${pageNumber}`);
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const handleAskQuestionClick = () => {
    if (!isLoggedIn || !user) {
      openLoginModal();
    } else {
      navigate("/ask");
    }
  };

  if (hasServerError) return <ServerErrorPage />;

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <div className="flex flex-1 justify-center mb-10">
        <div className="w-full">
          <div className="px-6 md:px-8 pt-8">
            <div className="flex items-center justify-between mb-8">
              <Link to="/qna?page=1" className="text-[24px] font-medium">
                Q&A
              </Link>
              <div
                onClick={handleAskQuestionClick}
                className="flex cursor-pointer shadow-button px-5 text-[14px] items-center bg-pointColor py-1.5 bg-li rounded-lg lg:hidden"
              >
                <SlNote className="w-3.5 h-3.5 mr-1" />
                질문하기
              </div>
            </div>
            <div className="flex text-[13px] justify-between items-center mb-2">
              <div className="flex">
                <span className="font-bold mr-5 mt-1 text-thirdText">
                  ORDER BY{" "}
                </span>
                <button
                  className={`py-1 mr-2 px-3 rounded-lg ${
                    sortOrder === "latest"
                      ? "bg-grayBox"
                      : "hover:bg-deepGrayBox"
                  } `}
                  onClick={() => handleSortOrderChange("latest")}
                >
                  최신순
                </button>
                <button
                  className={`py-0.5 px-3 rounded-lg ${
                    sortOrder === "popular"
                      ? "bg-grayBox"
                      : "hover:bg-deepGrayBox"
                  } `}
                  onClick={() => handleSortOrderChange("popular")}
                >
                  인기순
                </button>
              </div>
              {/* <div className="my-1">총 {totalCount}개</div> */}
            </div>
          </div>
          <div className="grid w-full">
            {questions.map((question) => (
              <QuestionItem key={question.id} {...question} />
            ))}
          </div>
          <Pagination
            itemsPerPage={QuestionsPerPage}
            totalItems={totalCount}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
        {/* <div
          onClick={handleAskQuestionClick}
          className="block md:hidden absolute bottom-28 right-10 rounded-full cursor-pointer px-4 py-4 bg-pointColor"
        >
          <HiPlus className="w-7 h-7" />
        </div> */}
      </div>
      {isLoginModalOpen && (
        <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
      )}
    </>
  );
};

export default QnAListPage;
