import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { getUserData, logout as logoutApi, refreshAccessToken } from "@api/api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => localStorage.getItem("isLoggedIn") === "true"
  );

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const deleteLoginInfo = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("isLoggedIn");

    setUser(null);
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const fetchUseEffect = async () => {
      if (localStorage.getItem("accessToken")) {
        try {
          const userData = await getUserData();

          setUser(userData);
          setIsLoggedIn(true);
          localStorage.setItem("user", JSON.stringify(userData));
          localStorage.setItem("isLoggedIn", "true");
        } catch (e) {
          deleteLoginInfo();
        }
      } else {
        try {
          await refreshAccessToken();

          const userData = await getUserData();

          setUser(userData);
          setIsLoggedIn(true);
          localStorage.setItem("user", JSON.stringify(userData));
          localStorage.setItem("isLoggedIn", "true");

          window.location.reload();
        } catch (e) {
          deleteLoginInfo();
        }
      }
    };
    fetchUseEffect();
  }, [children]);

  const login = useCallback(async () => {
    try {
      const userData = await getUserData();

      setIsLoggedIn(true);
      setUser(userData);
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("user", JSON.stringify(userData));
    } catch (e) {
      deleteLoginInfo();
      // console.error("Login failed: ", e);
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await logoutApi();
      deleteLoginInfo();
    } catch (e) {
      // console.error("Logout failed: ", e);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
