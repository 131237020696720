import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { searchQuestions } from "@api/api";
import Pagination from "@components/common/Pagination";
import QuestionItem from "@components/layout/QuestionItem";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchQnAPage = () => {
  const query = useQuery();
  const searchTerm = query.get("query");
  const location = useLocation();

  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [QuestionsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("latest");

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!searchTerm) {
        return;
      }

      try {
        const response = await searchQuestions(
          searchTerm,
          sortOrder,
          currentPage,
          QuestionsPerPage
        );
        setQuestions(response.questions);
        setTotalCount(response.totalCount);
      } catch (error) {
        console.error("검색 결과를 불러오는데 실패했습니다.", error);
      }
    };
    fetchSearchResults();
  }, [searchTerm, sortOrder, currentPage, QuestionsPerPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="flex flex-1 justify-center mb-10">
        <div className="w-full">
          <div className="px-8 pt-8">
            <div className="flex justify-between mb-8">
              <div className="flex text-xl ">
                Q&A <p className="font-bold mx-2">"{searchTerm}"</p> 검색 결과
              </div>
            </div>
            <div className="flex text-[13px] justify-between items-center mb-2">
              <div className="flex">
                <span className="font-bold mr-5 mt-1 text-thirdText">
                  ORDER BY{" "}
                </span>
                <button
                  className={`py-1 mr-2 px-3 rounded-lg ${
                    sortOrder === "latest"
                      ? "bg-grayBox"
                      : "hover:bg-deepGrayBox"
                  } `}
                  onClick={() => handleSortOrderChange("latest")}
                >
                  최신순
                </button>
                <button
                  className={`py-0.5 px-3 rounded-lg ${
                    sortOrder === "popular"
                      ? "bg-grayBox"
                      : "hover:bg-deepGrayBox"
                  } `}
                  onClick={() => handleSortOrderChange("popular")}
                >
                  인기순
                </button>
              </div>
              <div className=" text-sm">총 {totalCount}개</div>
            </div>
          </div>
          {/* <hr className="border-hrColor mt-4" /> */}
          <div className="grid w-full gap-1">
            {questions.length === 0 && (
              <div className="flex justify-center text-[18px] mt-20p text-secondText">
                검색 결과가 없습니다.
              </div>
            )}
            {questions.map((question) => (
              <QuestionItem key={question.id} {...question} />
            ))}
          </div>
          <Pagination
            itemsPerPage={QuestionsPerPage}
            totalItems={totalCount}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
};

export default SearchQnAPage;
