import React from "react";
import { Link } from "react-router-dom";

const ForbiddenPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black">
      <h1 className="text-6xl font-bold text-red-500 mb-4">403</h1>
      <h2 className="text-2xl text-secondText mb-6">접근이 금지되었습니다</h2>
      <p className="text-center text-gray-500 mb-8">
        이 페이지에 접근할 수 있는 권한이 없습니다.
      </p>
      <Link to="/" className="px-4 py-2 rounded hover:bg-pointColor">
        홈으로 돌아가기
      </Link>
    </div>
  );
};

export default ForbiddenPage;
