import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTechNote, updateTechNote } from "@api/api";
import TextEditor from "@components/common/TextEditor";
import LoadingPage from "./LoadingPage";

const UpdateTechNotePage = () => {
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  // eslint-disable-next-line
  const [textLength, setTextLength] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTechNoteDetails = async () => {
      setIsLoading(true); // 로딩 상태 설정
      try {
        const response = await getTechNote(id);
        if (response) {
          setTitle(response.title);
          setContent(response.body);
          setTextLength(response.body.length);
          setIsButtonDisabled(response.body.length < 30);
        } else {
          console.error("API 응답 형식이 올바르지 않습니다.", response);
        }
      } catch (error) {
        console.error("질문 데이터를 불러오는데 실패했습니다.", error);
      } finally {
        setIsLoading(false); // 로딩 완료
      }
    };

    fetchTechNoteDetails();
  }, [id]);

  if (isLoading) return <LoadingPage />;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = await updateTechNote(id, title, content);
    alert("기술 노트가 수정되었습니다!");
    navigate(`/technote/${data.id}`);
  };

  const handleEditorChange = (content, length) => {
    setContent(content);
    setTextLength(length);
    setIsButtonDisabled(length < 30);
  };

  return (
    <>
      <div className="w-full mb-10">
        <div className="text-center py-6">
          <div className="text-2xl font-bold mb-1">기술 노트 수정</div>
          <div className="font-semibold">
            <span className="font-JockeyOne mr-1">understand</span> 개발자님이
            알고 계신 지식을 정리해보세요.
          </div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit}
            className="my-5 flex flex-col w-80p mx-auto"
          >
            <div className="flex flex-col  rounded mb-5">
              <label htmlFor="title" className="text-lg font-bold mb-1">
                제목 작성하기
              </label>
              <span className="mb-2 text-secondText">
                기술 노트에 대한 제목을 적어주세요.
              </span>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="제목을 입력하세요"
                required
                className="p-2 mb-5 border bg-black placeholder-thirdText border-hrColor rounded outline-none"
              />
            </div>

            <div className="flex flex-col rounded mb-10">
              <label htmlFor="content" className="text-lg font-bold mb-1">
                본문 작성하기
              </label>
              <span className="mb-2 text-secondText">
                개발자님이 갖고 계신 지식을 정리해보세요. 다른 개발자에게도 성장
                할 수 있는 기회가 될 수 있습니다. 헤딩 태그를 사용해 문단별로
                작성하면 활용하면 더 좋은 글이 됩니다.
              </span>
              <TextEditor
                value={content}
                onChange={handleEditorChange}
                placeholder="최소 30자 이상 작성이 필요합니다."
              />

              <span className="text-red-400 text-[12px] mt-2">
                * 타인을 비방 또는 불쾌한 내용이 포함 될 경우 사전 고지 없이
                삭제 될 수 있습니다.
              </span>
            </div>

            <div className="flex justify-end mt-6 mb-20">
              <button
                type="submit"
                className={`inline-block py-1.5 text-linkColor rounded  ${
                  isButtonDisabled
                    ? " text-disableTextColor"
                    : " hover:font-bold"
                }`}
                disabled={isButtonDisabled}
              >
                기술 노트 수정하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateTechNotePage;
