import { useAuth } from "@contexts/AuthContext";
import React, { useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
// import linkifyHtml from "linkify-html";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import "../../styles/EditorContent.css";
import ImageResize from "quill-resize-image";

Quill.register("modules/ImageResize", ImageResize);

const CustomQuillEditorView = styled.div`
  .ql-toolbar {
    border: 1px solid #322f36;
    box-sizing: border-box;
  }

  .ql-container {
    border-right: 1px solid #322f36;
    border-bottom: 1px solid #322f36;
    border-left: 1px solid #322f36;
    height: auto;
    box-sizing: border-box;
    /* padding: 10px; */
  }

  .ql-editor {
    min-height: 200px;
    padding: 10px 0 0 15px;
  }

  /* Placeholder 스타일링 */
  .ql-editor.ql-blank::before {
    color: #acacac;
  }
`;

const TextEditor = ({ value, onChange, placeholder }) => {
  const { isLoggedIn } = useAuth();

  const quillRef = useRef(null);

  const handleChange = (content) => {
    if (onChange) {
      const text = content.replace(/<[^>]+>/g, ""); // HTML 태그 제거
      // const options = { defaultProtocol: "https", target: "_blank" };
      // const test = linkifyHtml(content, options);
      onChange(content, text.length); // HTML 콘텐츠와 순수 텍스트 길이를 전달
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ color: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
    ImageResize: {
      parchment: Quill.import("parchment"),
    },
    clipboard: {
      matchVisual: false,
    },
  };

  // const formats = [
  //   "header",
  //   "color",
  //   "bold",
  //   "italic",
  //   "underline",
  //   "strike",
  //   "blockquote",
  //   "code-block",
  //   "list",
  //   "bullet",
  //   "link",
  //   "image",
  // ];

  return (
    <CustomQuillEditorView>
      <ReactQuill
        id="quillContent"
        ref={quillRef}
        theme="snow"
        value={value}
        modules={modules}
        // formats={formats}
        onChange={handleChange}
        placeholder={isLoggedIn ? placeholder : "로그인 후 작성할 수 있습니다."}
        readOnly={!isLoggedIn}
      />
    </CustomQuillEditorView>
  );
};

export default TextEditor;
