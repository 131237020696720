import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { getUserAnswers } from "@api/api";
import { stripHtmlTags, cleanAndReplaceSpaces } from "@utils/utils";
import { Pagination } from "@components";
import LoadingPage from "@pages/LoadingPage";
import { ServerErrorPage } from "@pages/error";

const ActivityAnswerTab = () => {
  const { id } = useParams();
  const [answers, setAnswers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("latest");
  const [hasServerError, setHasServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [AnswersPerPage] = useState(10);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchUserAnswers = async () => {
      setIsLoading(true); // 로딩 상태 설정
      try {
        const response = await getUserAnswers(
          id,
          currentPage,
          AnswersPerPage,
          sortOrder
        );
        setAnswers(response.answers);
        setTotalCount(response.totalCount);
        setHasServerError(false);
      } catch (error) {
        console.error("답변 데이터를 불러오는데 실패했습니다.", error);
        setHasServerError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserAnswers();
  }, [currentPage, AnswersPerPage, sortOrder, id]);

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
  };

  if (hasServerError) return <ServerErrorPage />;

  if (isLoading) return <LoadingPage />;

  return (
    <div className="mb-10 w-10/12">
      <div className="flex justify-between mb-2 text-[12px]">
        <div className="mt-1">작성한 총 답변 {totalCount}개</div>
        <div className="flex space-x-2 ">
          <div className="py-1 mr-6 ">
            <button
              className="hover:font-bold mb-1"
              onClick={() => handleSortOrderChange("latest")}
            >
              최신순
            </button>
            {sortOrder === "latest" && (
              <div className="h-1 bg-pointColor rounded-full w-9"></div>
            )}
          </div>
          <div className="py-1">
            <button
              className="hover:font-bold mb-1"
              onClick={() => handleSortOrderChange("popular")}
            >
              인기순
            </button>
            {sortOrder === "popular" && (
              <div className="h-1 bg-pointColor rounded-full w-9"></div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full py-2 rounded-lg border border-hrColor">
        {answers.length === 0 && (
          <div className="flex justify-center text-thirdText items-center h-16 ">
            작성한 답변이 없습니다.
          </div>
        )}
        {answers.map((a) => (
          <div key={a.id} className="border-b border-hrColor py-2">
            <Link
              to={`/qna/${a.questionId}/${cleanAndReplaceSpaces(
                a.questionTitle
              )}`}
              className="text-inherit no-underline"
            >
              <div className="px-4 mb-1">
                <p className="text-linkColor">{stripHtmlTags(a.body)}</p>
                <div className="flex text-secondText">
                  <div className="flex items-center mt-2 text-[12px]">
                    <div className="flex items-center mr-2">
                      게시일. {a.createdAt}
                    </div>
                    {a.isEdited && (
                      <>
                        <div className="hidden md:block border-l-2 border-gray-400 text-secondText h-2.5 mr-2"></div>
                        <div className="hidden md:block text-secondText items-center mr-2">
                          수정됨. 마지막 수정일 {a.lastUpdated}
                        </div>
                      </>
                    )}
                    <div className="border-l-2 border-gray-400 text-secondText h-2.5 mr-2"></div>
                    <div className="flex items-center mr-2">
                      <FaStar className="text-yellow-500 h-3 w-3 mr-1" />
                      <span className="text-[12px] text-yellow-500 mr-2">
                        {a.approveCount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
        <Pagination
          itemsPerPage={AnswersPerPage}
          totalItems={totalCount}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
      <div className="mt-2 text-thirdText text-[11px] text-start mb-20">
        shot out to Korea developer <br />@
        <span className="font-JockeyOne mr-1">understand</span>
      </div>
    </div>
  );
};

export default ActivityAnswerTab;
