import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@contexts";

const Sidebar = ({ openLoginModal }) => {
  const location = useLocation();
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const handleAskQuestionClick = () => {
    if (!isLoggedIn || !user) {
      openLoginModal();
    } else {
      navigate("/ask");
    }
  };

  const linkClasses =
    "flex items-center pl-4 pr-7 py-1 rounded-lg space-x-4 cursor-pointer hover:bg-sidebarHover";
  const iconActiveClasses = "font-Jaro text-[18px]";
  const iconInactiveClasses = "font-Jaro text-[18px] text-thirdText";
  const containerClasses = "flex items-center";
  const activeClasses = "";
  const hrClasses = "border-hrColor my-3";

  return (
    <div className="border-black mt-5 px-6 lg:border-hrColor flex flex-col">
      <div>
        <ul className="text-[13px]">
          <Link
            to="/about"
            className={`${containerClasses} ${
              isActive("/about") ? activeClasses : ""
            }`}
          >
            <li className={linkClasses}>
              <div
                className={
                  isActive("/about") ? iconActiveClasses : iconInactiveClasses
                }
              >
                UN
              </div>
              <span>소개</span>
            </li>
          </Link>
          <hr className={hrClasses} />
          <Link
            to="/qna"
            className={`${containerClasses} ${
              isActive("/qna") ? activeClasses : ""
            } mb-2`}
          >
            <li className={linkClasses}>
              <div
                className={
                  isActive("/qna") ? iconActiveClasses : iconInactiveClasses
                }
              >
                QN
              </div>
              <span>Q&A</span>
            </li>
          </Link>
          <Link
            to="/technote"
            className={`${containerClasses} ${
              isActive("/technote") ? activeClasses : ""
            }`}
          >
            <li className={linkClasses}>
              <div
                className={
                  isActive("/technote")
                    ? iconActiveClasses
                    : iconInactiveClasses
                }
              >
                TE
              </div>
              <span>기술 노트</span>
            </li>
          </Link>
          <hr className={hrClasses} />
          {isLoggedIn && (
            <Link
              to="/notification"
              className={`${containerClasses} ${
                isActive("/notification") ? activeClasses : ""
              } mb-2`}
            >
              <li className={linkClasses}>
                <div
                  className={
                    isActive("/notification")
                      ? iconActiveClasses
                      : iconInactiveClasses
                  }
                >
                  NO
                </div>
                <div className="flex justify-start items-start">
                  <span className="mr-2">알림</span>
                  {user.hasUncheckedNotifications && (
                    <div className="w-2 h-2 bg-red-500 rounded-lg"></div>
                  )}
                </div>
              </li>
            </Link>
          )}
          <Link
            to="/users"
            className={`${containerClasses} ${
              isActive("/users") ? activeClasses : ""
            }`}
          >
            <li className={linkClasses}>
              <div
                className={
                  isActive("/users") ? iconActiveClasses : iconInactiveClasses
                }
              >
                DE
              </div>
              <span>개발자</span>
            </li>
          </Link>
          <hr className={hrClasses} />
          <Link
            to="/announcements"
            className={`${containerClasses} ${
              isActive("/announcements") ? activeClasses : ""
            }`}
          >
            <li className={linkClasses}>
              <div
                className={
                  isActive("/announcements")
                    ? iconActiveClasses
                    : iconInactiveClasses
                }
              >
                AN
              </div>
              <span>공지사항</span>
            </li>
          </Link>
        </ul>
        <div
          onClick={handleAskQuestionClick}
          className="hidden mt-10 bg-pointColor font-semibold py-3 px-4 rounded-lg mb-5 text-center cursor-pointer md:block"
        >
          질문하기
        </div>
      </div>
      <div className="text-thirdText text-[11px]">© 2024 understand, Inc.</div>
    </div>
  );
};

export default Sidebar;
