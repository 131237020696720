import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AtivityQuestionTab from "./ActivityQuestionTab";
import ActivityAnswerTab from "./ActivityAnswerTab";
import ActivityTechNoteTab from "./ActivityTechNoteTab";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ActivityTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const activeTab = query.get("tab") || "questions";

  const renderContent = () => {
    switch (activeTab) {
      case "questions":
        return <AtivityQuestionTab />;
      case "answers":
        return <ActivityAnswerTab />;
      case "technotes":
        return <ActivityTechNoteTab />;
      default:
        return null;
    }
  };

  const handleTabClick = (type) => {
    navigate(`${location.pathname}?tab=${type}`);
  };

  return (
    <div className="flex mt-5">
      <div className="flex w-1/12 flex-col space-y-2 mb-8 mr-10 text-[12px]">
        <button
          onClick={() => handleTabClick("questions")}
          className={`py-1.5 w-16 rounded-full hover:bg-boxColor ${
            activeTab === "questions" ? "bg-boxColor" : ""
          }`}
        >
          질문
        </button>
        <button
          onClick={() => handleTabClick("answers")}
          className={`py-1.5 w-16 rounded-full hover:bg-boxColor ${
            activeTab === "answers" ? "bg-boxColor" : ""
          }`}
        >
          답변
        </button>
        <button
          onClick={() => handleTabClick("technotes")}
          className={`py-1.5 w-16 rounded-full hover:bg-boxColor ${
            activeTab === "technotes" ? "bg-boxColor" : ""
          }`}
        >
          기술
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export default ActivityTab;
