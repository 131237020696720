import React from "react";

const LoadingPage = () => {
  return (
    <div className="grid h-screen w-full grid-cols-1 lg:grid-cols-3 items-center justify-items-center">
      <div className="hidden lg:block" />
      <div className="flex flex-col items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-400 h-24 w-24 mb-4" />
        <p className="text-lg font-semibold text-secondText">Loading...</p>
      </div>
      <div className="hidden lg:block" />
    </div>
  );
};

export default LoadingPage;
