import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { FaRegUser } from "react-icons/fa6";
import { LoginModal } from "@components";
import { cleanAndReplaceSpaces } from "@utils/utils";
import { useAuth } from "@contexts";
import UserProfileImage from "./UserProfileImage";

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { isLoggedIn, logout, user } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleNavigateToProfile = () => {
    navigate(
      `/users/${user.id}/${cleanAndReplaceSpaces(user.nickname)}/?tab=dashboard`
    );
  };

  const UserMenu = () => (
    <div
      ref={menuRef}
      className="absolute bg-menuColor right-2 mt-3 w-32 rounded-lg shadow-lg z-50 hover:text-white"
    >
      <button
        onClick={() => {
          toggleMenu();
          handleNavigateToProfile();
        }}
        className="flex items-center w-full text-left px-4 py-2.5 text-sm border-b border-hrColor hover:font-bold"
      >
        <FaRegUser className="mr-2" />
        프로필
      </button>
      <button
        className="flex items-center w-full text-left px-4 py-2.5 text-sm hover:font-bold"
        onClick={handleLogout}
      >
        <MdLogout className="mr-2" />
        로그아웃
      </button>
    </div>
  );

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm("");
    navigate(`/search?query=${searchTerm}`);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/qna");
    window.location.reload();
  };

  return (
    <div className="w-full bg-black items-center border-b border-hrColor py-2 sm:py-3">
      <div className="flex justify-between items-center px-6 sm:px-8">
        <div>
          <Link
            to="/qna?page=1"
            className="text-[20px] md:text-[22px] lg:text-[24px]"
          >
            <span className="font-JockeyOne mr-1">understand</span>
          </Link>
        </div>
        <div className="flex">
          <form
            onSubmit={handleSearch}
            className="flex-grow flex justify-center mx-4"
          >
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="검색어를 입력하세요"
              className="bg-boxColor placeholder-thirdText text-[12px] md:text-[14px] md:w-96 rounded-lg px-4 py-2 max-w-md focus:outline-none"
            />
          </form>
          {!isLoggedIn ? (
            <button onClick={() => setIsLoginModalOpen(true)}>로그인</button>
          ) : (
            <div>
              <div onClick={toggleMenu} className="items-center cursor-pointer">
                <div className="items-center">
                  <UserProfileImage
                    profilePicture={user.profilePicture}
                    imageStyle={"w-9 h-9 rounded-full"}
                  />
                </div>
              </div>
              {isMenuOpen && <UserMenu />}
            </div>
          )}
        </div>
      </div>
      {isLoginModalOpen && (
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Navbar;
