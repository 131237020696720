import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LoginModal } from "@components";
import { useAuth } from "@contexts";

const BottomNavbar = ({ toggleSidebar }) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const location = useLocation();

  const { isLoggedIn, user } = useAuth();

  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div className="w-full items-center py-2 sm:py-3 bg-black border-t border-hrColor">
      <div className="flex justify-center items-center">
        <ul
          className={`flex text-center items-center ${
            isLoggedIn ? "space-x-6" : "space-x-9"
          }  sm:space-x-14 text-[14px]`}
        >
          <Link
            onClick={toggleSidebar}
            to="/about"
            className={`flex items-center  ${
              isActive("/about") ? "text-white" : ""
            }`}
          >
            <li className="items-center text-center cursor-pointer">
              {isActive("/about") ? (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] py-1 px-2">
                  UN
                </div>
              ) : (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] text-thirdText rounded-full hover:bg-sidebarHover py-1 px-2">
                  UN
                </div>
              )}
              <span className="text-[11px] sm:text-[13px]">소개</span>
            </li>
          </Link>
          <Link
            onClick={toggleSidebar}
            to="/qna"
            className={`flex items-center  ${
              isActive("/qna") ? "text-white" : ""
            }`}
          >
            <li className="items-center text-center cursor-pointer">
              {isActive("/qna") ? (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] py-1 px-2">
                  QN
                </div>
              ) : (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] text-thirdText rounded-full hover:bg-sidebarHover py-1 px-2">
                  QN
                </div>
              )}
              <span className="text-[11px] sm:text-[13px]">Q&A</span>
            </li>
          </Link>
          <Link
            onClick={toggleSidebar}
            to="/technote"
            className={`flex items-center  ${
              isActive("/technote") ? "text-white" : ""
            }`}
          >
            <li className="items-center text-center cursor-pointer">
              {isActive("/technote") ? (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] py-1 px-2">
                  TE
                </div>
              ) : (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] text-thirdText rounded-full hover:bg-sidebarHover py-1 px-2">
                  TE
                </div>
              )}
              <span className="text-[11px] sm:text-[13px]">기술 노트</span>
            </li>
          </Link>
          {isLoggedIn && (
            <Link
              to="/notification"
              className={`flex items-center  ${
                isActive("/notification") ? "text-white" : ""
              }`}
            >
              <li className="items-center text-center cursor-pointer">
                {isActive("/notification") ? (
                  <div className="flex text-center py-1 px-2">
                    <div className="font-Jaro text-center text-[17px] sm:text-[18px] md:text-[22px]">
                      NO
                    </div>
                    {user.hasUncheckedNotifications && (
                      <div className="w-1 h-1 bg-red-500 rounded-full"></div>
                    )}
                  </div>
                ) : (
                  <div className="flex text-center py-1 px-2 rounded-full hover:bg-sidebarHover">
                    <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] text-thirdText">
                      NO
                    </div>
                    {user.hasUncheckedNotifications && (
                      <div className="w-1 h-1 bg-red-500 rounded-full"></div>
                    )}
                  </div>
                )}
                <span className="text-[11px] mr-1 sm:text-[12px]">알림</span>
              </li>
            </Link>
          )}
          <Link
            onClick={toggleSidebar}
            to="/users"
            className={`flex items-center  ${
              isActive("/users") ? "text-white" : ""
            }`}
          >
            <li className="items-center text-center cursor-pointer">
              {isActive("/users") ? (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] py-1 px-2">
                  DE
                </div>
              ) : (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] text-thirdText rounded-full hover:bg-sidebarHover py-1 px-2">
                  DE
                </div>
              )}
              <span className="text-[11px] sm:text-[13px]">개발자</span>
            </li>
          </Link>
          <Link
            onClick={toggleSidebar}
            to="/announcements"
            className={`flex items-center  ${
              isActive("/announcements") ? "text-white" : ""
            }`}
          >
            <li className="items-center text-center cursor-pointer">
              {isActive("/announcements") ? (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] py-1 px-2">
                  AN
                </div>
              ) : (
                <div className="font-Jaro text-[17px] sm:text-[18px] md:text-[22px] text-thirdText rounded-full hover:bg-sidebarHover py-1 px-2">
                  AN
                </div>
              )}
              <span className="text-[11px] sm:text-[13px]">공지사항</span>
            </li>
          </Link>
        </ul>
      </div>
      {isLoginModalOpen && (
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
        />
      )}
    </div>
  );
};

export default BottomNavbar;
