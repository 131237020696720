import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PiNumberOneBold,
  PiNumberTwoBold,
  PiNumberThreeBold,
} from "react-icons/pi";
import { getTopRankingList } from "@api/api";
import { cleanAndReplaceSpaces } from "@utils/utils";
import UserProfileImage from "./UserProfileImage";

const HallOfFame = () => {
  const [topRankingList, setTopRankingList] = useState([]);

  useEffect(() => {
    const fetchTopRankingList = async () => {
      const response = await getTopRankingList();
      setTopRankingList(response);
    };
    fetchTopRankingList();
  }, []);

  const rankColorClass = (rank) => {
    switch (rank) {
      case 1:
        return "text-yellow-500"; // 금색
      case 2:
        return "text-gray-300"; // 은색
      case 3:
        return "text-orange-400"; // 동색
      default:
        return "text-gray-500"; // 기본 색상
    }
  };

  return (
    <div className="border-hrColor border px-4 py-3 rounded-lg">
      <h2 className="text-[20px] font-bold">Top Developers</h2>
      <p className="text-thirdText text-[10px] mb-5">
        순위는 매일 자정에 업데이트 됩니다.
      </p>
      <ul className="space-y-4">
        {topRankingList.map((topRanking, index) => (
          <li key={topRanking.userId} className="flex justify-between">
            <div className="flex items-center">
              {index === 0 && (
                <PiNumberOneBold
                  className={`w-7 h-6 mr-1 ${rankColorClass(topRanking.rank)}`}
                />
              )}
              {index === 1 && (
                <PiNumberTwoBold
                  className={`w-7 h-6 mr-1 ${rankColorClass(topRanking.rank)}`}
                />
              )}
              {index === 2 && (
                <PiNumberThreeBold
                  className={`w-7 h-6 mr-1 ${rankColorClass(topRanking.rank)}`}
                />
              )}
              <UserProfileImage
                profilePicture={topRanking.profilePicture}
                imageStyle={"w-8 h-8 rounded-full mr-2"}
              />
              <Link
                to={`/users/${topRanking.userId}/${cleanAndReplaceSpaces(
                  topRanking.nickname
                )}/?tab=dashboard`}
              >
                <div className="text-[13px]">
                  <p className="w-full hover:text-linkColor">
                    {topRanking.nickname}
                  </p>
                </div>
              </Link>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex justify-end mt-3">
        <Link
          to="/users"
          className="text-linkColor text-[12px] block mt-2 mb-2 text-center"
        >
          전체 순위 보러가기
        </Link>
      </div>
    </div>
  );
};

export default HallOfFame;
