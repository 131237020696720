import React, { useEffect, useRef, useState } from "react";
import { MdReport } from "react-icons/md";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { StarButton, Answers, ReportModal } from "@components";
import {
  getQuestion,
  incrementQuestionViewsCount,
  reportQuestion,
} from "@api/api";
import { useAuth } from "@contexts";
import "../styles/EditorContent.css";
import LoadingPage from "./LoadingPage";
import ServerErrorPage from "./error/ServerErrorPage";
import UserProfileImage from "@components/common/UserProfileImage";
import { stripHtmlTags, cleanAndReplaceSpaces } from "@utils/utils";
import ogLogo from "@assets/ogLogo.png";

const QnADetailPage = () => {
  const { id, title } = useParams();
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null);

  const [question, setQuestion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasNotfoundError, setHasNotfoundError] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchQuestionDetails = async () => {
      setIsLoading(true);
      try {
        const response = await getQuestion(id);
        setQuestion(response);

        if (!title) {
          navigate(
            `${location.pathname}/${cleanAndReplaceSpaces(response.title)}`
          );
        }

        if (title !== cleanAndReplaceSpaces(response.title)) {
          navigate(`/qna/${id}/${cleanAndReplaceSpaces(response.title)}`);
        }

        await incrementQuestionViewsCount(id);
      } catch (error) {
        if (error.response.status === 404) {
          setHasNotfoundError(true);
          return;
        }
        // console.error("질문 데이터를 불러오는데 실패했습니다.", error);
        setHasServerError(true);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestionDetails();
  }, [id, location.pathname, navigate, title]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = () => {
    setIsMenuOpen(false);
    navigate(`/qna/${id}/edit`);
  };

  const openReportModal = () => {
    setIsReportModalOpen(true);
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false);
  };

  const handleReportSubmit = async (reason) => {
    await reportQuestion(id, reason);
    closeReportModal();
    alert("신고 처리가 완료되었습니다.");
  };

  if (hasNotfoundError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black">
        <h1 className="text-6xl font-bold text-secondText mb-4">404</h1>
        <h2 className="text-2xl text-secondText mb-6">
          요청하신 게시글을 찾을 수 없습니다
        </h2>
        <p className="text-center text-gray-500 mb-8">
          현재 존재하지 않는 게시글입니다.
        </p>
        <Link to="/" className="px-4 py-2 rounded hover:bg-pointColor">
          홈으로 돌아가기
        </Link>
      </div>
    );
  }

  if (hasServerError) return <ServerErrorPage />;

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <Helmet>
        <title>{question.title}</title>
        <meta name="description" content={stripHtmlTags(question.body, 100)} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={question.title} />
        <meta
          property="og:description"
          content={stripHtmlTags(question.body, 100)}
        />
        <meta property="og:image" content={ogLogo} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="min-h-screen flex flex-col mb-10">
        <div className="flex-1 justify-center">
          <div>
            <div className="px-6 sm:px-14 py-5">
              <div className="flex justify-between">
                <h1 className="text-[18px] md:text-[22px] font-bold">
                  {question.title}
                </h1>
                {isLoggedIn && user && user?.id === question.userId && (
                  <div className="relative" ref={menuRef}>
                    <button className="text-lg mr-2" onClick={toggleMenu}>
                      •••
                    </button>
                    {isMenuOpen && (
                      <div className="absolute bg-menuColor  right-0 mt-2 w-24 rounded-lg shadow-lg z-50 hover:text-white">
                        <button
                          className="block w-full text-left px-4 py-2 text-sm"
                          onClick={handleEdit}
                        >
                          질문 수정
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {isLoggedIn && user && user.id !== question.userId && (
                  <div onClick={openReportModal}>
                    <MdReport className="w-6 h-6 mt-1 hover:text-red-500 cursor-pointer" />
                  </div>
                )}
              </div>
              <div className="flex text-[12px] items-center mt-2">
                <Link
                  to={`/users/${question.userId}/${cleanAndReplaceSpaces(
                    question.nickname
                  )}?tab=dashboard`}
                  className="flex items-center mr-2"
                >
                  <UserProfileImage
                    profilePicture={question.profilePicture}
                    imageStyle={"w-4 h-4 rounded-full mr-2"}
                  />
                  <div className="flex">
                    <div className=" text-linkColor">
                      {question.nickname} Lv.{question.level}
                    </div>
                  </div>
                </Link>
                <div className="border-l-2 border-gray-400 text-secondText h-2.5 mr-2"></div>
                {question.isEdited ? (
                  <div className="text-secondText items-center mr-2">
                    수정됨. 마지막 수정일. {question.lastUpdated}
                  </div>
                ) : (
                  <div className="text-secondText items-center mr-2">
                    게시일. {question.lastUpdated}
                  </div>
                )}
                <div className="border-l-2 border-gray-400 text-secondText h-2.5 mr-2"></div>
                <div className="text-secondText items-center mr-2">
                  조회수. {question.viewsCount}
                </div>
              </div>
            </div>
            <hr className="border-hrColor" />
            <div className="w-full py-2 px-6 sm:px-14 mt-3">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: question.body }}
              ></div>
              <div className="flex justify-between mt-14">
                <StarButton
                  id={question.questionId}
                  contentType={"question"}
                  initialVoteCount={question.voteCount}
                  initialApproveVoted={question.approveVoted}
                />
                <div className="flex space-x-2 sm:text-[15px] font-bold">
                  <span className="hidden sm:block">Tags: [</span>
                  {question.tags.map((tag) => (
                    <Link to={`/qna/tag/${tag.name}`}>
                      <div className="px-3.5 py-0.5 bg-tagBg rounded-lg text-[13px] sm:text-[14px] hover:bg-hoverTagBg">
                        {tag.name}
                      </div>
                    </Link>
                  ))}
                  <span className="hidden sm:block">];</span>
                </div>
              </div>
            </div>
          </div>
          <Answers
            questionId={id}
            questionUserId={question.userId}
            isQuestionAccepted={question.isAccepted}
          />
        </div>
        <ReportModal
          isOpen={isReportModalOpen}
          onClose={closeReportModal}
          onSubmit={handleReportSubmit}
        />
      </div>
    </>
  );
};

export default QnADetailPage;
