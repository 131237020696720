import React, { useState } from "react";

const ReportModal = ({ isOpen, onClose, onSubmit }) => {
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = () => {
    if (reason.trim() === "") {
      alert("신고 사유를 입력해 주세요.");
      return;
    }
    onSubmit(reason);
    setReason("");
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-modalBg rounded-lg w-96 p-8 shadow-lg">
        <h2 className="text-xl font-semibold mb-7">게시글 신고</h2>
        <textarea
          className="w-full h-32 p-2 border bg-modalInput border-hrColor rounded-lg resize-none focus:outline-none"
          value={reason}
          onChange={handleReasonChange}
          placeholder="신고 사유를 입력해 주세요."
        ></textarea>
        <div className="flex justify-end space-x-4 mt-4">
          <button className="py-2 px-4 rounded-lg transition" onClick={onClose}>
            닫기
          </button>
          <button
            className="text-red-500 py-2 px-4 rounded-lg transition"
            onClick={handleSubmit}
          >
            신고
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
