import React, { useEffect, useRef, useState } from "react";
import { MdReport } from "react-icons/md";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { StarButton, ReportModal } from "@components";
import {
  getTechNote,
  incrementTechNoteViewsCount,
  reportTechNote,
} from "@api/api";
import { useAuth } from "@contexts";
import "../styles/EditorContent.css";
import LoadingPage from "./LoadingPage";
import ServerErrorPage from "./error/ServerErrorPage";
import UserProfileImage from "@components/common/UserProfileImage";
import { stripHtmlTags, cleanAndReplaceSpaces } from "@utils/utils";
import ogLogo from "@assets/ogLogo.png";
import TechNoteComments from "@components/layout/TechNoteComments";

const TechNoteDetailPage = () => {
  const { id, title } = useParams();
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null);

  const [techNote, setTechNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasNotfoundError, setHasNotfoundError] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchTechNoteDetails = async () => {
      setIsLoading(true);
      try {
        const response = await getTechNote(id);
        setTechNote(response);

        if (!title) {
          navigate(
            `${location.pathname}/${cleanAndReplaceSpaces(response.title)}`
          );
        }

        if (title !== cleanAndReplaceSpaces(response.title)) {
          navigate(`/technote/${id}/${cleanAndReplaceSpaces(response.title)}`);
        }

        await incrementTechNoteViewsCount(id);
      } catch (error) {
        if (error.response.status === 404) {
          setHasNotfoundError(true);
          return;
        }
        // console.error("기술 노트 데이터를 불러오는데 실패했습니다.", error);
        setHasServerError(true);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTechNoteDetails();
  }, [id, location.pathname, navigate, title]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = () => {
    setIsMenuOpen(false);
    navigate(`/technote/${id}/edit`);
  };

  const openReportModal = () => {
    setIsReportModalOpen(true);
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false);
  };

  const handleReportSubmit = async (reason) => {
    await reportTechNote(id, reason);
    closeReportModal();
    alert("신고 처리가 완료되었습니다.");
  };

  if (hasNotfoundError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black">
        <h1 className="text-6xl font-bold text-secondText mb-4">404</h1>
        <h2 className="text-2xl text-secondText mb-6">
          요청하신 기술 노트를 찾을 수 없습니다
        </h2>
        <p className="text-center text-gray-500 mb-8">
          현재 존재하지 않는 기술 노트입니다.
        </p>
        <Link to="/technote" className="px-4 py-2 rounded hover:bg-pointColor">
          홈으로 돌아가기
        </Link>
      </div>
    );
  }

  if (hasServerError) return <ServerErrorPage />;

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <Helmet>
        <title>{techNote.title}</title>
        <meta name="description" content={stripHtmlTags(techNote.body, 100)} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={techNote.title} />
        <meta
          property="og:description"
          content={stripHtmlTags(techNote.body, 100)}
        />
        <meta property="og:image" content={ogLogo} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="min-h-screen flex flex-col mb-10">
        <div className="flex-1 justify-center">
          <div>
            <div className="px-6 sm:px-14 py-5">
              <div className="flex justify-between">
                <h1 className="text-[18px] md:text-[22px] font-bold">
                  {techNote.title}
                </h1>
                {isLoggedIn && user && user?.id === techNote.userId && (
                  <div className="relative" ref={menuRef}>
                    <button className="text-lg mr-2" onClick={toggleMenu}>
                      •••
                    </button>
                    {isMenuOpen && (
                      <div className="absolute bg-menuColor  right-0 mt-2 w-24 rounded-lg shadow-lg z-50 hover:text-white">
                        <button
                          className="block w-full text-left px-4 py-2 text-sm"
                          onClick={handleEdit}
                        >
                          노트 수정
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {isLoggedIn && user && user.id !== techNote.userId && (
                  <div onClick={openReportModal}>
                    <MdReport className="w-6 h-6 mt-1 hover:text-red-500 cursor-pointer" />
                  </div>
                )}
              </div>
              <div className="flex text-[12px] items-center mt-2">
                <Link
                  to={`/users/${techNote.userId}/${cleanAndReplaceSpaces(
                    techNote.nickname
                  )}?tab=dashboard`}
                  className="flex items-center mr-2"
                >
                  <UserProfileImage
                    profilePicture={techNote.profilePicture}
                    imageStyle={"w-4 h-4 rounded-full mr-2"}
                  />
                  <div className="flex">
                    <div className="text-linkColor">
                      {techNote.nickname} Lv.{techNote.level}
                    </div>
                  </div>
                </Link>
                <div className="border-l-2 border-gray-400 text-secondText h-2.5 mr-2"></div>
                {techNote.isEdited ? (
                  <div className="text-secondText items-center mr-2">
                    수정됨. 마지막 수정일. {techNote.lastUpdated}
                  </div>
                ) : (
                  <div className="text-secondText items-center mr-2">
                    게시일. {techNote.lastUpdated}
                  </div>
                )}
                <div className="border-l-2 border-gray-400 text-secondText h-2.5 mr-2"></div>
                <div className="text-secondText items-center mr-2">
                  조회수. {techNote.viewsCount}
                </div>
              </div>
            </div>
            <hr className="border-hrColor" />
            <div className="w-full py-2 px-6 sm:px-14 mt-3">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: techNote.body }}
              ></div>
              <div className="flex mt-14">
                <StarButton
                  id={techNote.techNoteId}
                  contentType={"techNote"}
                  initialVoteCount={techNote.voteCount}
                  initialApproveVoted={techNote.approveVoted}
                />
              </div>
            </div>
          </div>
          <TechNoteComments techNoteId={id} techNoteUserId={techNote.userId} />
        </div>
        <ReportModal
          isOpen={isReportModalOpen}
          onClose={closeReportModal}
          onSubmit={handleReportSubmit}
        />
      </div>
    </>
  );
};

export default TechNoteDetailPage;
