import React from "react";

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="mt-5">
      <ul className="flex list-none p-0 justify-center mb-5">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`mx-1 text-base ${
              currentPage === number ? "bg-searchColor rounded-full" : ""
            }`}
          >
            <button
              onClick={() => paginate(number)}
              className={`cursor-pointer px-4 py-1 no-underline ${
                currentPage !== number ? "hover:bg-gray-400 rounded-full" : ""
              }`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;
