import React, { useState } from "react";
import { LoginModal } from "@components";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useAuth } from "@contexts";
import {
  voteAnswer,
  voteQuestion,
  voteTechNote,
  voteTechNoteComment,
} from "@api/api";

const StarButton = ({
  id,
  contentType,
  initialVoteCount,
  initialApproveVoted,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [voteCount, setVoteCount] = useState(initialVoteCount);
  const [approveVoted, setApproveVoted] = useState(initialApproveVoted);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { isLoggedIn, user } = useAuth();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleVote = async () => {
    try {
      if (!isLoggedIn || !user) {
        setIsLoginModalOpen(true);
      } else {
        const value = approveVoted ? -1 : 1;

        if (contentType === "question") {
          await voteQuestion(id, value);
        } else if (contentType === "answer") {
          await voteAnswer(id, value);
        } else if (contentType === "techNote") {
          await voteTechNote(id, value);
        } else if (contentType === "techNoteComment") {
          await voteTechNoteComment(id, value);
        }

        setVoteCount(voteCount + value);
        setApproveVoted(!approveVoted);
      }
    } catch (error) {
      alert("투표에 실패하였습니다.");
      // console.error("Error voting:", error);
    }
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  return (
    <>
      <button
        className="flex items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleVote}
      >
        {isHovered && !approveVoted ? (
          <div className="flex items-center text-secondText">
            <div className="flex items-center border border-secondText px-3 rounded-full py-0.5">
              <FaRegStar className="h-15px w-15px" />{" "}
              <span className="ml-2">{voteCount}</span>
            </div>
          </div>
        ) : approveVoted ? (
          <div className="flex items-center text-secondText">
            <div className="flex items-center border border-thirdText px-3 rounded-full py-0.5">
              <FaStar className="text-yellow-500 h-15px w-15px" />{" "}
              <span className="ml-2">{voteCount}</span>
            </div>
          </div>
        ) : (
          <div className="flex items-center text-thirdText">
            <div className="flex items-center border border-thirdText px-3 rounded-full py-0.5">
              <FaRegStar className="h-15px w-15px" />{" "}
              <span className="ml-2">{voteCount}</span>
            </div>
          </div>
        )}
      </button>
      {isLoginModalOpen && (
        <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
      )}
    </>
  );
};

export default StarButton;
