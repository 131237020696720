import React, { useEffect, useState } from "react";
import { getAnswers, postAnswer } from "@api/api";
import { TextEditor } from "@components";
import AnswerItem from "./AnswerItem";

const Answers = ({ questionId, questionUserId, isQuestionAccepted }) => {
  const [answers, setAnswers] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // eslint-disable-next-line
  const [textLength, setTextLength] = useState(0);
  const [answerContent, setAnswerContent] = useState("");
  const [sortOrder, setSortOrder] = useState("bydate");

  useEffect(() => {
    const fetchAnswers = async () => {
      await refreshAnswers();
    };
    fetchAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, sortOrder]);

  const refreshAnswers = async () => {
    try {
      const response = await getAnswers(questionId, sortOrder);
      setAnswers(response);
    } catch (error) {
      console.error("답변 목록 갱신에 실패했습니다.", error);
    }
  };

  const handleEditorChange = (content, length) => {
    setAnswerContent(content);
    setTextLength(length);
    setIsButtonDisabled(length < 30);
  };

  const handleAnswerSubmit = async () => {
    try {
      await postAnswer(questionId, answerContent);
      alert("답변 작성이 완료되었습니다.");
      setAnswerContent("");

      await refreshAnswers(questionId);
    } catch (error) {
      console.error("답변 제출에 실패했습니다.", error);
    }
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
  };

  return (
    <>
      {answers.length > 0 && (
        <div>
          <div className="flex mb-5 justify-between px-6 sm:px-14 items-center mt-20">
            <h2>총 {answers.length}개의 답변</h2>
            <div className="flex">
              <span className="font-bold mr-5 mt-1 text-thirdText">
                ORDER BY{" "}
              </span>
              <button
                className={`py-1 mr-2 px-3 rounded-lg ${
                  sortOrder === "bydate" ? "bg-grayBox" : "hover:bg-deepGrayBox"
                } `}
                onClick={() => handleSortOrderChange("bydate")}
              >
                날짜순
              </button>
              <button
                className={`py-0.5 px-3 rounded-lg ${
                  sortOrder === "popular"
                    ? "bg-grayBox"
                    : "hover:bg-deepGrayBox"
                } `}
                onClick={() => handleSortOrderChange("popular")}
              >
                인기순
              </button>
            </div>
          </div>
          <hr className="border-hrColor mt-2 mb-5" />
          {answers.map((answer) => (
            <AnswerItem
              key={answer.id}
              {...answer}
              refreshAnswers={refreshAnswers}
              questionUserId={questionUserId}
              isQuestionAccepted={isQuestionAccepted}
            />
          ))}
        </div>
      )}
      <div className="mb-20 px-6 sm:px-14">
        <div className="mt-20">
          <div className="mb-5 text-[17px]">
            나의 답변
            <span className="text-[12px] text-secondText">
              {" "}
              (최소 30자 이상 작성이 필요합니다.)
            </span>
          </div>
          <TextEditor
            value={answerContent}
            onChange={handleEditorChange}
            placeholder="질문자의 고민 해결을 위해 소중한 지식을 공유해주세요"
          />
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleAnswerSubmit}
            disabled={isButtonDisabled}
            className={`mt-8 ${
              isButtonDisabled ? "text-disableTextColor cursor-not-allowed" : ""
            }`}
          >
            답변 완료
          </button>
        </div>
      </div>
    </>
  );
};

export default Answers;
