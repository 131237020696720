export function stripHtmlTags(html, length = 100) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  if (doc.body.textContent.length <= length)
    return doc.body.textContent.slice(0, length) || "";
  else return `${doc.body.textContent.slice(0, length)}..` || "";
}

export function cleanAndReplaceSpaces(str) {
  return str
    .replace(/[^\x20-\x7E\uAC00-\uD7AF]/g, "") // 깨지는 문자 제거
    .replace(/[\s/]+/g, "-"); // 공백을 '-'로 대체
}
