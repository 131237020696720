import React, { useState } from "react";
import { deleteUser } from "@api/api";

const WithdrawModal = ({ isOpen, onClose }) => {
  const [reason, setReason] = useState("");
  // const navigate = useNavigate();
  // const { logout } = useAuth();

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    if (reason.trim() === "") {
      alert("탈퇴 사유를 입력해 주세요.");
      return;
    }
    // await logout();
    await deleteUser(reason);
    alert("탈퇴 신청이 완료 되었습니다.");
    onClose();
    // navigate("/qna");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      {/* <div className="bg-modalBg rounded-lg w-30p 2xl:20p p-6 shadow-lg"> */}
      <div className="bg-modalBg rounded-lg w-96 p-8 shadow-lg">
        <h2 className="text-xl font-semibold mb-7">회원 탈퇴</h2>
        <span className="text-red-500">
          탈퇴는 접수일로 부터 1~3일 정도 소요되며 <br /> 탈퇴 완료 시 처리 완료
          메일이 전송됩니다.
        </span>
        <textarea
          className="w-full h-32 placeholder-thirdText mt-3 p-2 border bg-modalInput border-hrColor rounded-lg resize-none focus:outline-none"
          value={reason}
          onChange={handleReasonChange}
          placeholder="탈퇴 사유를 입력해 주세요....."
        ></textarea>
        <p className="text-thirdText text-[11px]">
          * 관련하여 문의 사항은 understandevcom@gmail.com 으로 메일
          부탁드립니다.
        </p>
        <div className="flex justify-end space-x-4 mt-4">
          <button className="py-2 px-4 rounded-lg transition" onClick={onClose}>
            닫기
          </button>
          <button
            className="text-red-500 py-2 px-4 rounded-lg transition"
            onClick={handleSubmit}
          >
            탈퇴
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;
