import React, { useEffect, useMemo, useState } from "react";
import { SlNote } from "react-icons/sl";
import { getTechNotes } from "@api/api";
import { Pagination } from "@components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import { ServerErrorPage } from "@pages";
import { useAuth } from "@contexts/AuthContext";
import { LoginModal } from "@components/modal";
import TechNoteItem from "@components/layout/TechNoteItem";

const TechNoteListPage = () => {
  const [techNotes, setTechNotes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [techNotesPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("latest");
  const [hasServerError, setHasServerError] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, user } = useAuth();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentPage = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    if (!queryParams.has("page")) {
      navigate("?page=1", { replace: true });
    }
  }, [queryParams, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchTechNotes = async () => {
      setIsLoading(true); // 로딩 상태 설정
      try {
        const response = await getTechNotes(
          sortOrder,
          currentPage,
          techNotesPerPage
        );
        setTechNotes(response.techNotes);
        setTotalCount(response.totalCount);
        setHasServerError(false);
      } catch (error) {
        console.error("질문 데이터를 불러오는데 실패했습니다.", error);
        setHasServerError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTechNotes();
  }, [sortOrder, currentPage, techNotesPerPage]);

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
  };

  const paginate = (pageNumber) => {
    navigate(`?page=${pageNumber}`);
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const handleCreateTechNoteClick = () => {
    if (!isLoggedIn || !user) {
      openLoginModal();
    } else {
      navigate("/technote/create");
    }
  };

  if (hasServerError) return <ServerErrorPage />;

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <div className="flex flex-1 justify-center mb-10">
        <div className="w-full">
          <div className="px-6 md:px-8 pt-8">
            <div className="flex items-center  justify-between mb-8">
              <Link to="/technote?page=1" className="text-[24px] font-medium">
                기술 노트
              </Link>
              <div
                onClick={handleCreateTechNoteClick}
                className="flex px-5 text-[14px] items-center py-1.5 bg-pointColor shadow-button cursor-pointer rounded-lg"
              >
                <SlNote className="w-3.5 h-3.5 mr-1" />
                노트 작성
              </div>
            </div>
            <div className="flex text-[13px] justify-between items-center mb-2">
              <div className="flex">
                <span className="font-bold mr-5 mt-1 text-thirdText">
                  ORDER BY{" "}
                </span>
                <button
                  className={`py-1 mr-2 px-3 rounded-lg ${
                    sortOrder === "latest"
                      ? "bg-grayBox"
                      : "hover:bg-deepGrayBox"
                  } `}
                  onClick={() => handleSortOrderChange("latest")}
                >
                  최신순
                </button>
                <button
                  className={`py-0.5 px-3 rounded-lg ${
                    sortOrder === "popular"
                      ? "bg-grayBox"
                      : "hover:bg-deepGrayBox"
                  } `}
                  onClick={() => handleSortOrderChange("popular")}
                >
                  인기순
                </button>
              </div>
              {/* <div className="my-1">총 {totalCount}개</div> */}
            </div>
          </div>
          <div className="grid w-full">
            {techNotes.length === 0 && (
              <div className="flex justify-center text-[18px] font-bold my-20">
                아직 작성된 노트가 없습니다.
              </div>
            )}
            {techNotes.map((techNote) => (
              <TechNoteItem key={techNote.id} {...techNote} />
            ))}
          </div>
          {techNotes.length > 0 && (
            <Pagination
              itemsPerPage={techNotesPerPage}
              totalItems={totalCount}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
        {/* <div
          onClick={handleCreateTechNoteClick}
          className="block lg:hidden absolute bottom-20 right-10 rounded-full cursor-pointer px-4 py-4 bg-pointColor"
        >
          <HiPlus className="w-7 h-7" />
        </div> */}
      </div>
      {isLoginModalOpen && (
        <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
      )}
    </>
  );
};

export default TechNoteListPage;
