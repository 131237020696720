import React from "react";
import { Link } from "react-router-dom";

const NotFoundErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black">
      <h1 className="text-6xl font-bold text-secondText mb-4">404</h1>
      <h2 className="text-2xl text-secondText mb-6">
        페이지를 찾을 수 없습니다
      </h2>
      <p className="text-center text-gray-500 mb-8">
        요청하신 페이지가 존재하지 않거나 다른 곳으로 이동되었습니다.
      </p>
      <Link to="/" className="px-4 py-2 rounded hover:bg-pointColor">
        홈으로 돌아가기
      </Link>
    </div>
  );
};

export default NotFoundErrorPage;
