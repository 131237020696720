import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postTechNote } from "@api/api";
import { ForbiddenPage } from "@pages";
import { useAuth } from "@contexts";
import { TextEditor } from "@components/common";

const WriteTechNotePage = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (title || content) {
        event.preventDefault();
        event.returnValue = ""; // 브라우저에서 경고 메시지를 띄우기 위한 설정
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [title, content]);

  const { isLoggedIn, user } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!title) {
        alert("제목을 입력해주세요");
      }
      const data = await postTechNote(null, title, content, "published");
      alert("노트가 추가 되었습니다.");
      navigate(`/technote/${data.id}`);
    } catch (e) {
      alert("작성에 실패하였습니다.");
    }
  };

  const handleEditorChange = (content, length) => {
    setContent(content);
    setIsButtonDisabled(length < 30);
  };

  if (!isLoggedIn || !user) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <div className="w-full mb-10">
        <div className="text-center py-6">
          <div className="text-[24px] font-bold mb-1">기술 노트 작성</div>
          <div className="text-base font-semibold">
            <span className="font-JockeyOne mr-1">understand</span> 개발자님이
            알고 계신 지식을 정리해보세요.
          </div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit}
            className="my-5 flex flex-col w-80p mx-auto"
          >
            <div className="flex flex-col  rounded mb-10">
              <label htmlFor="title" className="text-lg font-bold mb-1">
                제목 작성하기
              </label>
              <span className="mb-2 text-secondText">
                기술 노트에 대한 제목을 적어주세요.
              </span>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="제목을 입력하세요"
                required
                className="p-2 mb-5 border bg-black placeholder-thirdText border-hrColor rounded outline-none"
              />
            </div>

            <div className="flex flex-col rounded mb-10">
              <label htmlFor="content" className="text-lg font-bold mb-1">
                본문 작성하기
              </label>
              <span className="text-secondText mb-2">
                개발자님이 갖고 계신 지식을 정리해보세요. 다른 개발자에게도 성장
                할 수 있는 기회가 될 수 있습니다. 헤딩 태그를 사용해 문단별로
                작성하면 활용하면 더 좋은 글이 됩니다.
              </span>
              <TextEditor
                value={content}
                onChange={handleEditorChange}
                placeholder="최소 30자 이상 작성이 필요합니다."
              />

              <span className="text-red-400 text-[12px] mt-2">
                * 타인을 비방 또는 불쾌한 내용이 포함 될 경우 사전 고지 없이
                삭제 될 수 있습니다.
              </span>
            </div>

            <div className="flex justify-end mt-6 mb-20">
              <button
                type="submit"
                onClick={handleSubmit}
                className={`inline-block py-1.5 px-1 text-linkColor rounded ${
                  isButtonDisabled
                    ? " text-disableTextColor"
                    : " hover:font-bold"
                }`}
                disabled={isButtonDisabled}
              >
                기술 노트 작성하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default WriteTechNotePage;
