import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { MdReport } from "react-icons/md";
import { useAuth } from "@contexts";
import { updateAnswer, reportAnswer } from "@api/api";
import { ReportModal, TextEditor, StarButton } from "@components";
import UserProfileImage from "@components/common/UserProfileImage";
import { cleanAndReplaceSpaces } from "@utils/utils";
import AcceptButton from "@components/common/AcceptButton";

const AnswerItem = ({
  id,
  lastUpdated,
  body,
  userId,
  nickname,
  isEdited,
  profilePicture,
  level,
  isAccepted,
  voteCount,
  approveVoted,
  refreshAnswers,
  questionUserId,
  isQuestionAccepted,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // eslint-disable-next-line
  const [textLength, setTextLength] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [updatedContent, setUpdatedContent] = useState(body);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const { isLoggedIn, user } = useAuth();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = () => {
    setIsMenuOpen(false);
    setIsUpdated(true);
  };

  const handleSave = async () => {
    try {
      if (textLength < 30) {
        alert("30자 이상 작성이 필요합니다.");
        return;
      }
      await updateAnswer(id, updatedContent);
      setIsUpdated(false);
      await refreshAnswers();
      alert("답변 수정이 완료되었습니다.");
    } catch (error) {
      console.error("답변 수정에 실패했습니다.", error);
    }
  };

  const handleEditorChange = (content, length) => {
    setUpdatedContent(content);
    setTextLength(length);
    setIsButtonDisabled(length < 30);
  };

  const openReportModal = () => {
    setIsReportModalOpen(true);
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false);
  };

  const handleReportSubmit = async (reason) => {
    await reportAnswer(id, reason);
    closeReportModal();
    alert("신고 처리가 완료되었습니다.");
  };

  return (
    <div className="py-3">
      <div className="flex px-6 sm:px-14">
        {isUpdated ? (
          <div className="px-8 py-2 w-full">
            <div>
              <TextEditor
                value={updatedContent}
                onChange={handleEditorChange}
              />
            </div>
            <div className="flex justify-end mt-5">
              <button
                className="px-5 py-1 rounded"
                onClick={() => setIsUpdated(false)}
              >
                취소
              </button>
              <button
                disabled={isButtonDisabled}
                onClick={handleSave}
                className={`px-5 py-1 ${
                  isButtonDisabled
                    ? "text-disableTextColor cursor-not-allowed"
                    : ""
                }`}
              >
                수정하기
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex justify-between mb-5">
              <div className="flex text-[12px] items-center">
                <Link
                  to={`/users/${userId}/${cleanAndReplaceSpaces(
                    nickname
                  )}?tab=dashboard`}
                  className="flex mr-2 items-center"
                >
                  <UserProfileImage
                    profilePicture={profilePicture}
                    imageStyle={"w-4 h-4 rounded-full mr-2"}
                  />
                  <div className=" text-blue-400">
                    {nickname} Lv.{level}
                  </div>
                  {questionUserId === userId && (
                    <div className="ml-2 bg-pointColor px-1.5 rounded-lg">
                      질문자
                    </div>
                  )}
                </Link>
                {isAccepted && (
                  <>
                    <div className="border-l-2 border-gray-400 h-2.5 mr-2"></div>
                    <div className="block font-bold text-green-600 mr-2 sm:hidden">
                      채택
                    </div>
                    <div className="hidden font-bold bg-green-600 rounded-lg px-2 mr-2 sm:block">
                      채택된 답변
                    </div>
                  </>
                )}
                <div className="border-l-2 border-gray-400 h-2.5 mr-2"></div>
                <div className="flex items-center">
                  {isEdited ? (
                    <div className="text-secondText items-center mr-2">
                      수정됨. 마지막 수정일. {lastUpdated}
                    </div>
                  ) : (
                    <div className="text-secondText items-center mr-2">
                      게시일. {lastUpdated}
                    </div>
                  )}
                </div>
              </div>
              {isLoggedIn && user && user.id === userId && (
                <div className="relative" ref={menuRef}>
                  <button
                    className="mr-2 ml-3 items-center py-1"
                    onClick={toggleMenu}
                  >
                    •••
                  </button>
                  {isMenuOpen && (
                    <div className="absolute bg-menuColor right-0 mt-2 w-24 rounded-lg shadow-lg z-50 hover:text-white">
                      <button
                        className="block w-full text-left px-4 py-2"
                        onClick={handleEdit}
                      >
                        답변 수정
                      </button>
                    </div>
                  )}
                </div>
              )}
              {isLoggedIn && user && user.id !== userId && (
                <div className="flex" onClick={openReportModal}>
                  <MdReport className="w-5 h-5 mt-2 hover:text-red-500 cursor-pointer" />
                </div>
              )}
            </div>
            <div className="flex mt-5">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: body }}
              ></div>
            </div>
            <div className="flex items-center text-secondText mt-8">
              <StarButton
                id={id}
                contentType={"answer"}
                initialVoteCount={voteCount}
                initialApproveVoted={approveVoted}
              />
              {!isQuestionAccepted &&
                user &&
                questionUserId === user.id &&
                questionUserId !== userId && <AcceptButton answerId={id} />}
            </div>
          </div>
        )}
      </div>
      <hr className="border-hrColor mt-5" />
      <ReportModal
        isOpen={isReportModalOpen}
        onClose={closeReportModal}
        onSubmit={handleReportSubmit}
      />
    </div>
  );
};

export default AnswerItem;
