import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postQuestion, searchQuestionTags } from "@api/api";
import { ForbiddenPage } from "@pages";
import { useAuth } from "@contexts";
import { TextEditor } from "@components/common";

const AskQuestionPage = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [suggestedTags, setSuggestedTags] = useState([]); // 유사한 태그를 위한 상태 추가
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  const { isLoggedIn, user } = useAuth();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (title || content || tags.length > 0) {
        event.preventDefault();
        event.returnValue = ""; // 브라우저에서 경고 메시지를 띄우기 위한 설정
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [title, content, tags]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!title) {
        alert("제목을 입력해주세요");
      }
      const data = await postQuestion(null, title, content, "published", tags);
      alert("질문이 제출되었습니다");
      navigate(`/qna/${data.id}`);
    } catch (e) {
      alert("질문 작성에 실패하였습니다.");
    }
  };

  const handleEditorChange = (content, length) => {
    setContent(content);
    setIsButtonDisabled(length < 30);
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagKeyPress = (e) => {
    if (e.key === "Enter" && tagInput.trim()) {
      e.preventDefault();
      addTag();
    }
  };

  const addTag = () => {
    if (tagInput.trim() && !tags.includes(tagInput.trim())) {
      if (tags.length >= 5) {
        alert("태그는 최대 5개 입니다.");
        return;
      }
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
      setSuggestedTags([]);
    }
  };

  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleTagSuggestionClick = (suggestedTag) => {
    if (!tags.includes(suggestedTag)) {
      setTags([...tags, suggestedTag]);
      setTagInput("");
      setSuggestedTags([]);
    }
  };

  const fetchSuggestedTags = async () => {
    if (!tagInput.trim()) {
      setSuggestedTags([]);
      return;
    }

    try {
      const tags = await searchQuestionTags(tagInput);
      setSuggestedTags(tags);
    } catch (error) {
      console.error("유사한 태그를 검색하는 중 오류가 발생했습니다:", error);
      setSuggestedTags([]);
    }
  };

  useEffect(() => {
    if (tagInput.length > 0) {
      fetchSuggestedTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagInput]);

  const getSuggestedTags = () => {
    if (!tagInput.trim()) return [];

    return suggestedTags;
  };

  if (!isLoggedIn || !user) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <div className="w-full mb-10">
        <div className="text-center py-6">
          <div className="text-[24px] font-bold mb-1">질문하기</div>
          <div className="text-base font-semibold">
            <span className="font-JockeyOne mr-1">understand</span> 문제를
            공유하고 공유의 힘을 경험해보세요
          </div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit}
            className="my-5 flex flex-col w-80p mx-auto"
          >
            <div className="flex flex-col  rounded mb-10">
              <label htmlFor="title" className="text-lg font-bold mb-1">
                제목 작성하기
              </label>
              <span className="mb-2 text-secondText">
                겪고 있는 문제를 파악하기 쉽게 한줄로 표현해주세요.
              </span>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="제목을 입력하세요"
                required
                className="p-2 mb-5 border bg-black placeholder-thirdText border-hrColor rounded outline-none"
              />
            </div>

            <div className="flex flex-col rounded mb-10">
              <label htmlFor="content" className="text-lg font-bold mb-1">
                질문 작성하기
              </label>
              <span className="text-secondText mb-2">
                문제를 설명해주세요. 더 자세히 설명할 수록 해결할 수 있는 확률이
                높아집니다. 사람들과 논의할 수 있게 해보세요. 헤딩 태그를 사용해
                문단별로 작성하면 활용하면 더 좋은 글이 됩니다.
              </span>
              <span className="text-sm mb-2 text-secondText">
                ( understand는 한국 개발자들이 함께 성장을 위한 공간입니다.
                질문은 신중히 작성해 주세요. 개발과 상관없는 질문시 작성자의
                동의 없이 삭제 될 수 있습니다. )
              </span>
              <TextEditor
                value={content}
                onChange={handleEditorChange}
                placeholder="최소 30자 이상 작성이 필요합니다."
              />

              <span className="text-red-400 text-[12px] mt-2">
                * 타인을 비방 또는 불쾌한 내용이 포함 될 경우 사전 고지 없이
                삭제 될 수 있습니다.
              </span>
            </div>

            {/* 태그 입력 필드 */}
            <div className="flex flex-col rounded mb-10 relative">
              <label htmlFor="tags" className="text-lg font-bold mb-1">
                태그 추가하기
              </label>
              <span className="text-secondText mb-2">
                관련 태그를 추가하여 더 많은 사람들이 당신의 질문을 찾을 수
                있도록 하세요. ( 최대 5개 )
              </span>
              <div className="flex flex-wrap gap-2">
                {tags.map((tag, index) => (
                  <div
                    key={index}
                    className="bg-tagBg px-3 py-1 rounded-full flex items-center"
                  >
                    {tag}
                    <button
                      type="button"
                      className="ml-2 text-red-500"
                      onClick={() => removeTag(index)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="text"
                id="tags"
                value={tagInput}
                onChange={handleTagInputChange}
                onKeyPress={handleTagKeyPress}
                placeholder="태그를 입력하고 Enter를 누르세요"
                className="p-2 mt-3 border bg-black placeholder-thirdText border-hrColor rounded outline-none"
              />
              {/* 태그 제안 목록 */}
              {getSuggestedTags().length > 0 && (
                <div className="absolute top-full left-0 mt-1 w-full bg-black border border-hrColor rounded shadow-lg z-10">
                  {getSuggestedTags().map((suggestedTag, index) => (
                    <div
                      key={suggestedTag.id}
                      className="p-2 cursor-pointer hover:bg-tagBg"
                      onClick={() =>
                        handleTagSuggestionClick(suggestedTag.name)
                      }
                    >
                      {suggestedTag.name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-end mt-6 mb-20">
              <button
                type="submit"
                onClick={handleSubmit}
                className={`inline-block py-1.5 px-1 text-linkColor rounded ${
                  isButtonDisabled
                    ? " text-disableTextColor"
                    : " hover:font-bold"
                }`}
                disabled={isButtonDisabled}
              >
                질문 작성하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AskQuestionPage;
