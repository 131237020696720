import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getAnnouncement, incrementAnnouncementViewsCount } from "@api/api";
import LoadingPage from "./LoadingPage";
import ServerErrorPage from "./error/ServerErrorPage";
import { Helmet } from "react-helmet-async";
import { stripHtmlTags } from "@utils/utils";
import ogLogo from "@assets/ogLogo.png";

const AnnouncementDetailPage = () => {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const [hasServerError, setHasServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNotfoundError, setHasNotfoundError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchAnnouncementDetails = async () => {
      setIsLoading(true); // 로딩 상태 설정
      try {
        const response = await getAnnouncement(id);

        setAnnouncement(response.announcement);
        await incrementAnnouncementViewsCount(id);
      } catch (error) {
        // console.error("공지 데이터를 불러오는데 실패했습니다.", error);
        if (error.response.status === 404) {
          setHasNotfoundError(true);
          return;
        }
        setHasServerError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnnouncementDetails();
  }, [id]);

  if (hasNotfoundError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black">
        <h1 className="text-6xl font-bold text-secondText mb-4">404</h1>
        <h2 className="text-2xl text-secondText mb-6">
          요청하신 게시글을 찾을 수 없습니다
        </h2>
        <p className="text-center text-gray-500 mb-8">
          현재 존재하지 않는 게시글입니다.
        </p>
        <Link to="/" className="px-4 py-2 rounded hover:bg-pointColor">
          홈으로 돌아가기
        </Link>
      </div>
    );
  }

  if (hasServerError) return <ServerErrorPage />;

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{announcement.title}</title>
        <meta
          name="description"
          content={stripHtmlTags(announcement.body, 100)}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={announcement.title} />
        <meta
          property="og:description"
          content={stripHtmlTags(announcement.body, 100)}
        />
        <meta property="og:image" content={ogLogo} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="flex flex-col mb-10">
        <div className="flex flex-1 justify-center">
          <div className="mt-5 rounded-lg">
            <h1 className="text-2xl px-8 mb-2 font-bold">
              {announcement.title}
            </h1>
            <div className="flex px-8 items-center text-[12px]">
              <div className="flex items-center mr-2">관리자 Lv.???</div>
              <div className="border-l-2 border-secondText h-2.5 mr-2"></div>
              <div className="text-secondText items-center">
                게시일 {announcement.createdAt}
              </div>
            </div>
            <hr className="border-hrColor mt-4" />
            <div className="mt-4 px-8 py-4 rounded-lg">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: announcement.body }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementDetailPage;
