import React from "react";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

const SignupSuccessModal = ({ user, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-modalBg p-12 rounded-lg shadow-lg w-full max-w-lg min-w-[300px] min-h-[200px] relative md:max-w-xl lg:max-w-2xl">
        <button
          className="absolute top-5 right-5 text-thirdText hover:text-gray-700"
          onClick={onClose}
        >
          <IoClose className="w-7 h-7 hover:text-white" />
        </button>
        <h2 className="text-xl mt-5">
          <span className="text-3xl font-bold">welcome.json</span>
        </h2>
        <div className="bg-black py-3 px-5 my-5 rounded-lg space-y-1">
          <div>&#123;</div>
          <div className="ml-10">
            <span className="text-jsonKeyColor">"welcomeMessage"</span>:
            <span className="ml-1 text-jsonValueColor">
              "{user.nickname} 개발자님, 환영합니다."
            </span>
            ,
          </div>
          <div className="ml-10">
            <span className="text-jsonKeyColor">"description"</span>: &#123;
          </div>

          <div className="ml-20">
            <span className="text-jsonKeyColor">"about"</span>:
            <span className="ml-1 text-jsonValueColor">
              "언더스탠은 오직 한국
              <span className="font-bold text-jsonPointColor"> 개발자</span>
              만을 위한 서비스입니다."
            </span>
            ,
          </div>
          <div className="ml-20">
            <span className="text-jsonKeyColor">"features"</span>: [
          </div>
          <div className="ml-32">
            <span className="text-jsonValueColor">
              "
              <span className="font-bold text-jsonPointColor">
                에러 및 고민
              </span>
              에 대해 공유해보세요"
            </span>
          </div>
          <div className="ml-32">
            <span className="text-jsonValueColor">
              "나만의{" "}
              <span className="font-bold text-jsonPointColor">
                퍼스널 개발 프로필
              </span>
              을 만들어보세요"
            </span>
          </div>
          <div className="ml-32">
            <span className="text-jsonValueColor">
              "질문하고 답변을 하며 레벨을 올리고{" "}
              <span className="font-bold text-jsonPointColor">랭크</span>에
              참여해보세요."
            </span>
            ,
          </div>
          <div className="ml-20">]</div>
          <div className="ml-10">&#125;</div>
          <div>&#125;</div>
        </div>
        <div className="flex justify-end text-center space-x-3">
          <Link to="/users" className="py-2 px-5 rounded-lg">
            <button
              onClick={onClose}
              className="focus:outline-none focus:shadow-outline"
            >
              유저 랭크 확인
            </button>
          </Link>
          <Link
            to={`/users/${user.id}/${user.nickname}?tab=dashboard`}
            className="py-2 px-5 rounded-lg bg-pointColor"
          >
            <button
              onClick={onClose}
              className="text-white focus:outline-none focus:shadow-outline"
            >
              나의 프로필 보기
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignupSuccessModal;
