import React, { useEffect, useState } from "react";
import {
  IoArrowUpCircleSharp,
  IoHeartCircle,
  IoCheckmarkDoneCircle,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import { cleanAndReplaceSpaces, stripHtmlTags } from "@utils/utils";
import LoadingPage from "@pages/LoadingPage";
import { checkNotifications, getNotifications } from "@api/api";

const LevelUpNoti = ({ notification, checkNotification }) => {
  return (
    <Link
      onClick={() => checkNotification(notification.id)}
      to={`/users/${notification.user.id}/${cleanAndReplaceSpaces(
        notification.user.nickname
      )}?tab=dashboard`}
      className="flex py-3.5 bg-modalBg rounded-lg px-4 shadow-bottom-left cursor-pointer"
    >
      <IoArrowUpCircleSharp className="hidden mr-2 w-8 h-8 sm:block" />
      <div className="w-full">
        <div className="flex justify-between text-[12px] mb-1">
          <div className="flex text-secondText items-center">
            <span>개발자님의 레벨이 올랐습니다!</span>
          </div>
          <div className="text-thirdText flex">
            {notification.createdAt}
            {!notification.isChecked && (
              <div className="w-1 h-1 sm:w-2 sm:h-2 ml-2 bg-red-500 rounded-full"></div>
            )}
          </div>
        </div>
        <div>{notification.message}</div>
      </div>
    </Link>
  );
};

const NewAnswerNoti = ({ notification, checkNotification }) => {
  return (
    <Link
      onClick={() => checkNotification(notification.id)}
      to={`/qna/${notification.question.id}/${cleanAndReplaceSpaces(
        notification.question.title
      )}`}
      className="flex py-3.5 bg-modalBg rounded-lg px-4 shadow-bottom-left cursor-pointer"
    >
      <IoCheckmarkDoneCircle className="hidden mr-2 w-8 h-8 sm:block" />
      <div className="w-full">
        <div className="flex justify-between text-[12px] mb-1">
          <div className="flex text-secondText items-center">
            <span>질문에 새로운 답변이 달렸습니다.</span>
          </div>
          <div className="text-thirdText flex">
            {notification.createdAt}
            {!notification.isChecked && (
              <div className="w-1 h-1 sm:w-2 sm:h-2 ml-2 bg-red-500 rounded-full"></div>
            )}
          </div>
        </div>
        <div>
          <span className="mr-2">
            {stripHtmlTags(notification.question.title, 25)}:
          </span>
          <span className="font-semibold">{notification.message}</span>
        </div>
      </div>
    </Link>
  );
};

const NewTechNoteCommentNoti = ({ notification, checkNotification }) => {
  return (
    <Link
      onClick={() => checkNotification(notification.id)}
      to={`/technote/${notification.techNote.id}/${cleanAndReplaceSpaces(
        notification.techNote.title
      )}`}
      className="flex py-3.5 bg-modalBg rounded-lg px-4 shadow-bottom-left cursor-pointer"
    >
      <IoCheckmarkDoneCircle className="hidden mr-2 w-8 h-8 sm:block" />
      <div className="w-full">
        <div className="flex justify-between text-[12px] mb-1">
          <div className="flex text-secondText items-center">
            <span>기술 노트에 새로운 코멘트가 달렸습니다.</span>
          </div>
          <div className="text-thirdText flex">
            {notification.createdAt}
            {!notification.isChecked && (
              <div className="w-1 h-1 sm:w-2 sm:h-2 ml-2 bg-red-500 rounded-full"></div>
            )}
          </div>
        </div>
        <div>
          <span className="mr-2">
            {stripHtmlTags(notification.techNote.title, 25)}:
          </span>
          <span className="font-semibold">{notification.message}</span>
        </div>
      </div>
    </Link>
  );
};

const VoteNoti = ({ notification, checkNotification }) => {
  let linkTo;

  switch (notification.type) {
    case "questionVote":
      linkTo = `/qna/${notification.question.id}/${cleanAndReplaceSpaces(
        notification.question.title
      )}`;
      break;
    case "answerVote":
      linkTo = `/qna/${notification.answer.question.id}/${cleanAndReplaceSpaces(
        notification.answer.question.title
      )}`;
      break;
    case "techNoteVote":
      linkTo = `/technote/${notification.techNote.id}/${cleanAndReplaceSpaces(
        notification.techNote.title
      )}`;
      break;
    case "techNoteCommentVote":
      linkTo = `/technote/${
        notification.techNoteComment.techNote.id
      }/${cleanAndReplaceSpaces(notification.techNoteComment.techNote.title)}`;
      break;
    case "profileVote":
      linkTo = `/users/${notification.user.id}/${notification.user.nickname}`;
      break;
    default:
      linkTo = `/qna`;
  }

  return (
    <Link
      onClick={() => checkNotification(notification.id)}
      to={linkTo}
      className="flex py-3.5 bg-modalBg rounded-lg px-4 shadow-bottom-left cursor-pointer"
    >
      <IoHeartCircle className="hidden mr-2 w-8 h-8 sm:block" />
      <div className="w-full">
        <div className="flex justify-between text-[12px] mb-1">
          <div className="flex text-secondText items-center">
            <span>좋아요를 받았습니다!</span>
          </div>
          <div className="text-thirdText flex">
            {notification.createdAt}
            {!notification.isChecked && (
              <div className="w-1 h-1 sm:w-2 sm:h-2 ml-2 bg-red-500 rounded-full"></div>
            )}
          </div>
        </div>
        <div>
          {notification.type === "questionVote" && (
            <span className="mr-2">
              {stripHtmlTags(notification.question.title, 25)}:
            </span>
          )}
          {notification.type === "answerVote" && (
            <span className="mr-2">
              {stripHtmlTags(notification.answer.body, 25)}:
            </span>
          )}
          {notification.type === "techNoteVote" && (
            <span className="mr-2">
              {stripHtmlTags(notification.techNote.title, 25)}:
            </span>
          )}
          {notification.type === "techNoteCommentVote" && (
            <span className="mr-2">
              {stripHtmlTags(notification.techNoteComment.body, 25)}:
            </span>
          )}
          <span className="font-semibold">{notification.message}</span>
        </div>
      </div>
    </Link>
  );
};

const NotificationPage = () => {
  const [notifications, setNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setIsLoading(true); // 로딩 상태 설정
        const notifications = await getNotifications();
        setNotification(notifications);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchNotifications();
  }, []);

  const checkNotification = async (notificationId) => {
    await checkNotifications(notificationId);
  };

  if (isLoading) return <LoadingPage />;

  if (!notifications) return <LoadingPage />;

  return (
    <div className="flex mb-10">
      <div className="w-full h-full">
        <div className="flex p-8">
          <div className="text-[24px] font-medium">알림</div>
        </div>
        <hr className="border-hrColor" />
        <div className="grid w-auto mt-10 px-8">
          <ul className="space-y-5">
            {notifications.length ? (
              notifications.map((notification) => (
                <li key={notification.id}>
                  {notification.type === "level" && (
                    <LevelUpNoti
                      notification={notification}
                      checkNotification={checkNotification}
                    />
                  )}
                  {notification.type === "newAnswer" && (
                    <NewAnswerNoti
                      notification={notification}
                      checkNotification={checkNotification}
                    />
                  )}
                  {notification.type === "newTechNoteComment" && (
                    <NewTechNoteCommentNoti
                      notification={notification}
                      checkNotification={checkNotification}
                    />
                  )}
                  {[
                    "questionVote",
                    "answerVote",
                    "techNoteVote",
                    "techNoteCommentVote",
                    "profileVote",
                  ].includes(notification.type) && (
                    <VoteNoti
                      notification={notification}
                      checkNotification={checkNotification}
                    />
                  )}
                </li>
              ))
            ) : (
              <li>새로운 알림이 없습니다.</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
