import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: apiUrl, // 백엔드 서버 주소
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // 쿠키를 요청에 포함
});

const getAccessToken = () => {
  const token = localStorage.getItem("accessToken");
  return token;
};

const setAccessToken = (token) => {
  localStorage.setItem("accessToken", token);
};

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        await refreshAccessToken();
        const token = getAccessToken();
        originalRequest.headers["Authorization"] = `Bearer ${token}`;

        return api(originalRequest);
      } catch (e) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const checkNicknameDuplication = async (nickname) => {
  try {
    const response = await api.get(
      `users/nickname/duplication?nickname=${nickname}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDraftQuestion = async () => {
  const response = await api.get("questions/draft");
  return response.data;
};

export const postQuestion = async (id, title, body, status, tags) => {
  try {
    const response = await api.post("questions", {
      id,
      title,
      body,
      status,
      tags,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const postTechNote = async (id, title, body, status) => {
  try {
    const response = await api.post("technotes", {
      id,
      title,
      body,
      status,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getQuestions = async (sortBy, page, limit) => {
  try {
    const response = await api.get(
      `questions?sortBy=${sortBy}&page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getTechNotes = async (sortBy, page, limit) => {
  try {
    const response = await api.get(
      `technotes?sortBy=${sortBy}&page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getTechNote = async (techNoteId) => {
  try {
    const response = await api.get(`technotes/${techNoteId}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getQuestion = async (questionId) => {
  try {
    const response = await api.get(`questions/${questionId}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getAnnouncement = async (id) => {
  try {
    const response = await api.get(`announcements/${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const postAnswer = async (questionId, body) => {
  try {
    await api.post("answers", { questionId, body });
  } catch (e) {
    throw e;
  }
};

export const postTechNoteComment = async (techNoteId, body) => {
  try {
    await api.post("technotecomments", { techNoteId, body });
  } catch (e) {
    throw e;
  }
};

export const refreshAccessToken = async () => {
  try {
    const response = await api.post(
      "/auth/refresh",
      {},
      { withCredentials: true }
    );
    setAccessToken(response.data.accessToken);

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const logout = async () => {
  try {
    await api.post("auth/logout", {});
    localStorage.removeItem("accessToken");
  } catch (e) {
    throw e;
  }
};

export const voteQuestion = async (questionId, value) => {
  try {
    await api.post(`/votes/question/${questionId}`, {
      voteType: "approve",
      value,
    });
  } catch (e) {
    throw e;
  }
};

export const voteAnswer = async (answerId, value) => {
  try {
    await api.post(`/votes/answer/${answerId}`, {
      voteType: "approve",
      value,
    });
  } catch (e) {
    throw e;
  }
};

export const voteTechNote = async (techNoteId, value) => {
  try {
    await api.post(`/votes/technote/${techNoteId}`, {
      voteType: "approve",
      value,
    });
  } catch (e) {
    throw e;
  }
};

export const voteTechNoteComment = async (techNoteCommentId, value) => {
  try {
    await api.post(`/votes/technotecomment/${techNoteCommentId}`, {
      voteType: "approve",
      value,
    });
  } catch (e) {
    throw e;
  }
};

export const reportQuestion = async (questionId, description) => {
  try {
    await api.post("/reports/question", {
      questionId,
      description,
    });
  } catch (e) {
    throw e;
  }
};

export const reportTechNote = async (techNoteId, description) => {
  try {
    await api.post("/reports/technote", {
      techNoteId,
      description,
    });
  } catch (e) {
    throw e;
  }
};

export const reportTechNoteComment = async (techNoteCommentId, description) => {
  try {
    await api.post("/reports/technotecomment", {
      techNoteCommentId,
      description,
    });
  } catch (e) {
    throw e;
  }
};

export const reportAnswer = async (answerId, description) => {
  try {
    await api.post("/reports/answer", {
      answerId,
      description,
    });
  } catch (e) {
    throw e;
  }
};

export const deleteUser = async (reason) => {
  try {
    await api.post("/users/delete", { reason });
  } catch (e) {
    throw e;
  }
};

export const getAnnouncements = async (page, limit) => {
  try {
    const response = await api.get(
      `announcements?&page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getUserProfile = async (userId) => {
  const response = await api.get(`/users/profile/${userId}`);
  return response.data;
};

export const getUserData = async () => {
  try {
    const response = await api.get("users");
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getUserTechNotes = async (id, page, limit, sortBy) => {
  const response = await api.get(
    `users/${id}/technotes?&page=${page}&limit=${limit}&sortBy=${sortBy}`
  );
  return response.data;
};

export const getUserAnswers = async (id, page, limit, sortBy) => {
  const response = await api.get(
    `users/${id}/answers?&page=${page}&limit=${limit}&sortBy=${sortBy}`
  );
  return response.data;
};

export const getUserQuestions = async (id, page, limit, sortBy) => {
  const response = await api.get(
    `users/${id}/questions?&page=${page}&limit=${limit}&sortBy=${sortBy}`
  );
  return response.data;
};

export const updateUserData = async ({
  nickname,
  isEmailConsent,
  about,
  githubUrl,
  websiteUrl,
  book,
  company,
  techStack,
}) => {
  const response = await api.put("users", {
    nickname,
    isEmailConsent,
    about,
    githubUrl,
    websiteUrl,
    book,
    company,
    techStack,
  });
  return response.data;
};

export const getRankingList = async (page, limit) => {
  const response = await api.get(`users/rankings?&page=${page}&limit=${limit}`);
  return response.data;
};

export const incrementAnnouncementViewsCount = async (announcementId) => {
  await api.post(`announcements/${announcementId}/views`);
};

export const incrementQuestionViewsCount = async (questionId) => {
  await api.post(`questions/${questionId}/views`);
};

export const incrementTechNoteViewsCount = async (techNoteId) => {
  await api.post(`technotes/${techNoteId}/views`);
};

export const getTopRankingList = async () => {
  const response = await api.get("users/rankings/top");
  return response.data;
};

// export const deleteQuestion = async (questionId) => {
//   await api.delete(`questions/${questionId}`);
// };

// export const deleteAnswer = async (answerId) => {
//   await api.delete(`answers/${answerId}`);
// };

export const updateQuestion = async (questionId, title, body, tags) => {
  const response = await api.put(`questions/${questionId}`, {
    title,
    body,
    tags,
  });
  return response.data;
};

export const updateTechNote = async (techNoteId, title, body) => {
  const response = await api.put(`technotes/${techNoteId}`, {
    title,
    body,
  });
  return response.data;
};

export const updateAnswer = async (answerId, body) => {
  const response = await api.put(`answers/${answerId}`, {
    body,
  });
  return response.data;
};

export const updateTechNoteComment = async (commentId, body) => {
  const response = await api.put(`technotecomments/${commentId}`, {
    body,
  });
  return response.data;
};

export const sendPasswordAuthEmail = async (email) => {
  try {
    const response = await api.post("auth/send-auth-email", { email });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const verifyResetCode = async (email, code) => {
  try {
    const response = await api.post("auth/login", {
      email,
      code,
    });

    setAccessToken(response.data.accessToken);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getQuestionByTag = async (tagName, sortBy, page, limit) => {
  try {
    const response = await api.get(
      `questions/tags/?tagName=${tagName}&sortBy=${sortBy}&page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchQuestions = async (searchText, sortBy, page, limit) => {
  try {
    const response = await api.get(
      `questions/search?searchText=${searchText}&sortBy=${sortBy}&page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const voteUserProfile = async (userId) => {
  const response = await api.post(`users/profile/${userId}/vote`);
  return response.data;
};

export const getUserProfileVote = async (userId) => {
  const response = await api.get(`users/profile/${userId}/vote`);
  return response.data;
};

export const getAnswers = async (questionId, sortBy) => {
  const response = await api.get(
    `answers?questionId=${questionId}&sortBy=${sortBy}`
  );
  return response.data;
};

export const getTechNoteComments = async (techNoteId, sortBy) => {
  const response = await api.get(
    `technotecomments?techNoteId=${techNoteId}&sortBy=${sortBy}`
  );
  return response.data;
};

export const searchQuestionTags = async (q) => {
  const response = await api.get(`tags/search?q=${q}`);
  return response.data;
};

export const landingPageButtonClickCount = async () => {
  try {
    const response = await api.post(`/users/landing/button`);
    return response.data;
  } catch (e) {
    //
  }
};

export const landingPageClientIp = async () => {
  try {
    const response = await api.post(`/users/landing`);
    return response.data;
  } catch (e) {
    //
  }
};

export const uploadProfileImage = async (file) => {
  await api.post(
    "upload/profile",
    { file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const deleteProfileImage = async () => {
  try {
    await api.delete("upload/profile");
  } catch (error) {
    throw error;
  }
};

export const acceptAnswer = async (id) => {
  try {
    await api.put(`answers/accept/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getNotifications = async () => {
  try {
    const response = await api.get(`notifications`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkNotifications = async (id) => {
  try {
    const response = await api.patch(`notifications/check/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default api;
