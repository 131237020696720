import React from "react";
import { Link } from "react-router-dom";

const ServerErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black">
      <h1 className="text-6xl font-bold text-secondText mb-4">500</h1>
      <h2 className="text-2xl text-secondText mb-6">
        서버에 문제가 발생했습니다
      </h2>
      <p className="text-center text-gray-500 mb-2">
        요청을 처리하는 중에 문제가 발생했습니다. 잠시 후 다시 시도해 주세요.
      </p>
      <p className="text-center text-gray-500 mb-8">
        문제가 계속 될 경우 understandevcom@gmail.com 으로 메일 주세요.
        감사합니다.
      </p>
      <Link to="/" className="px-4 py-2 rounded hover:bg-pointColor">
        홈으로 돌아가기
      </Link>
    </div>
  );
};

export default ServerErrorPage;
