import React, { useEffect, useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { LoginModal } from "@components";
import { useAuth } from "@contexts";
import { getUserProfileVote, voteUserProfile } from "@api/api";

const ProfileStarButton = ({ userId, initialVoteCount }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [voteCount, setVoteCount] = useState(initialVoteCount);
  const [isVoted, setIsVoted] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { isLoggedIn, user } = useAuth();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const getVote = async () => {
      setVoteCount(initialVoteCount);
      if (isLoggedIn) {
        const vote = await getUserProfileVote(userId);
        setIsVoted(vote);
      }
    };
    getVote();
  }, [userId, initialVoteCount, isLoggedIn]);

  const handleVote = async () => {
    try {
      if (!isLoggedIn || !user) {
        setIsLoginModalOpen(true);
      } else {
        const vote = await voteUserProfile(userId);
        setVoteCount(voteCount + vote);
        setIsVoted(vote === 1 ? true : false);
      }
    } catch (error) {
      // console.error("Error voting:", error);
    }
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  return (
    <>
      <button
        className="flex mt-1.5"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleVote}
      >
        {isHovered || isVoted ? (
          <div className="flex ml-0.5 items-center py-0.5 sm:py-1 px-1 sm:px-2 border border-yellow-600 rounded-lg">
            <FaStar className="text-yellow-600 items-center h-4 w-4" />
            <span className="hidden sm:block ml-1 text-[12px] items-center font-semibold text-yellow-600">
              STAR
            </span>
            <span className="ml-1 text-[12px] items-center font-semibold text-yellow-600">
              {voteCount}
            </span>
          </div>
        ) : (
          <div className="flex ml-0.5 items-center py-0.5 sm:py-1 px-1 sm:px-2 border border-thirdText rounded-lg">
            <FaRegStar className="text-thirdText  items-center h-4 w-4" />
            <span className="ml-1 hidden sm:block text-[12px] items-center font-semibold text-thirdText">
              STAR
            </span>
            <span className="ml-1 text-[12px] items-center font-semibold text-thirdText">
              {voteCount}
            </span>
          </div>
        )}
      </button>
      {isLoginModalOpen && (
        <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
      )}
    </>
  );
};

export default ProfileStarButton;
