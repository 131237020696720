import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { HallOfFame } from "@components";
import rightSideImage from "@assets/rightSideImage.png";

const RightSidebar = () => {
  const [setIsMenuOpen] = useState(false);

  const menuRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsMenuOpen]);

  return (
    <div className="mr-4 p-6 flex flex-col space-y-4">
      <HallOfFame />
      <img src={rightSideImage} alt="ad" className="h-auto rounded-lg mb-4" />
      <p className="text-secondText text-[10px]">
        ** 버그나 기능 추가에 대한 의견들은 understandevcom@gmail.com 으로 메일
        부탁드립니다.
      </p>
    </div>
  );
};

export default RightSidebar;
