import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { landingPageButtonClickCount, landingPageClientIp } from "@api/api";
import keyborad from "@assets/IMG_5209.jpg";
import customKeyborad from "@assets/IMG_8056.jpg";
import { LoginModal } from "@components/modal";
import { useAuth } from "@contexts/AuthContext";

const AboutPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const openLoginModal = () => {
    setIsSignupModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsSignupModalOpen(false);
    navigate("/qna");
  };

  useEffect(() => {
    const handleLandingPageCount = async () => {
      try {
        await landingPageClientIp();
      } catch (e) {
        // console.log("error");
      }
    };
    handleLandingPageCount();
  });

  const handleGettingStart = async () => {
    if (!isLoggedIn) {
      landingPageButtonClickCount();
      openLoginModal();
      return;
    }

    navigate("/qna");
  };

  return (
    <div className="flex flex-col items-left w-full mb-10 justify-center">
      <div className="font-JockeyOne bg-textColor text-black py-10 text-center text-[25px]">
        <span className="text-secondText">better than today,</span> understand
      </div>
      <div className="text-textColor shadow-lg rounded-lg px-15p py-12 text-left">
        <div className="text-[35px] font-semibold mb-16">
          성장을 원하는
          <br />
          모든 한국의 개발자들을 위해
          <br />
        </div>
        <p className="text-lg mb-10">
          저는 주니어 개발자이며, <br />
          <span className="font-bold">
            개발자의 성장의 공간{" "}
            <span className="font-JockeyOne">understand</span>를 만들게
            되었습니다.
          </span>
        </p>

        <p className="text-lg mb-10">
          취업 준비부터 신입으로 일할 때까지, 개발 관련 질문은 정말 많았지만
          혼자 해결해야 할 때가 대부분이었습니다.
          <br /> 오래된 블로그 검색과 영어 번역에 지치면서 개발에 대한 흥미도
          잃어갔던 시기도 있었습니다.
        </p>
        <p className="text-lg mb-10">
          5년차가 넘어가는 지금도 여전히 개발에 대한 많은 질문과, 더 성장하고
          싶은 마음이 있습니다.
          <br /> 저와 같은 어려움을 겪고 있는 주니어 개발자분들, 그리고 성장하고
          싶은 개발자분들을 위한 공간을 만들었습니다.
          <br />
          <span className="text-secondText">
            ( 여러 유지비용을 내며 오픈하고 있습니다. 오직 개발자분들 성장을
            위하는 마음이며 제 진심이 닿길 바랍니다. )
          </span>
        </p>
        <p className="text-lg mb-16">
          <span className="font-JockeyOne">understand</span>에서 개발자분들의
          건강한 개발 문화를 만들기 위해 노력하겠습니다. 성장을 위한다면, 함께
          동참해주세요.
          <br />
          <br /> 모두 자신이 원하는, 그리고 즐거운 개발을 하시길 바랍니다.
          <br /> 감사합니다.
          <br />
        </p>

        <div className="flex items-center justify-center mb-2">
          <img
            src={customKeyborad}
            alt="keybord"
            className="h-44 w-44 sm:h-52 sm:w-52  mr-2 sm:mr-5"
          />
          <img
            src={keyborad}
            alt="keybord"
            className="h-44 w-44 sm:h-52 sm:w-60"
          />
        </div>
        <div className="flex justify-center mb-20 text-secondText text-[13px]">
          저는 제가 사랑하는 제 커스텀 키보드 <br className="block sm:hidden" />{" "}
          로 개발할 때가 가장 행복해요.
        </div>
        <div className="flex justify-end my-10">
          <button
            className="bg-pointColor mb-2 text-white text-lg font-medium py-2.5 px-4 rounded-lg"
            onClick={handleGettingStart}
          >
            다른 개발자의 고민 보러가기
          </button>
        </div>
      </div>
      {isSignupModalOpen && (
        <LoginModal isOpen={isSignupModalOpen} onClose={closeLoginModal} />
      )}
    </div>
  );
};

export default AboutPage;
