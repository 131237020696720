import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { cleanAndReplaceSpaces, stripHtmlTags } from "@utils/utils";

const DashboardTab = ({ userProfile }) => {
  const [progress, setProgress] = useState(0);
  const [isHovered, setIsHovered] = useState(false); // 호버 상태 추가

  const actualProgress =
    (userProfile.currentExperience / userProfile.experienceThreshold) * 100;

  const levelName = [
    "Hello World",
    "Syntax Checker",
    "Debugger",
    "Code Refactorer",
    "Algorithm Master",
    "Data Structure Guru",
    "Concurrency Expert",
    "Full Stack Pro",
    "Systems Architect",
    "Language Creator",
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(actualProgress);
    }, 500);

    return () => clearTimeout(timeout);
  }, [actualProgress]);

  return (
    <>
      <div className="flex mt-5 space-x-4">
        <div className="w-50p">
          <div className="mb-3">Ranking</div>
          <div className="p-3 sm:p-6 h-40 border border-hrColor rounded-lg flex items-center space-x-2 sm:space-x-6">
            <div className="relative w-24 h-24">
              <svg
                className="absolute top-0 left-0 w-full h-full transform -rotate-90"
                viewBox="0 0 36 36"
              >
                <path
                  className="text-gray-700"
                  stroke="currentColor"
                  strokeWidth="3.8"
                  fill="none"
                  d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                  className="text-blue-600 transition-all duration-1000 ease-out"
                  stroke="currentColor"
                  strokeWidth="3.8"
                  strokeDasharray={`${progress}, 100`}
                  fill="none"
                  d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center text-[14px] sm:text-[19px] font-bold">
                Lv.{userProfile.level}
              </div>
            </div>

            {/* Text Section */}
            <div className="relative">
              <p className="text-[15px] sm:text-xl font-bold">
                {userProfile.currentExperience}xp
              </p>
              <div
                className="hidden sm:block text-[15px] relative"
                onMouseEnter={() => setIsHovered(true)} // 호버 시작
                onMouseLeave={() => setIsHovered(false)} // 호버 종료
              >
                ({userProfile.levelName})
                {isHovered && (
                  <div className="absolute top-0 left-14 ml-2 bg-modalBg w-64 py-3 px-5 rounded-lg z-10">
                    <span className="text-[16px] font-bold">-LEVEL NAME-</span>
                    <div className="mt-4">
                      {levelName.map((name, index) => (
                        <div
                          className={`mb-1 ${
                            userProfile.level === index + 1
                              ? "font-bold"
                              : "text-thirdText"
                          }`}
                        >
                          <span>Lv.{index + 1}</span>
                          <span className="ml-2">{name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <p className="text-gray-500 mt-2 text-[10px] sm:text-[12px]">
                다음 레벨까지
                <span className="ml-1">
                  {userProfile.experienceThreshold -
                    userProfile.currentExperience}
                  xp
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="w-50p">
          <div className=" mb-3">Ranking</div>
          <div className="flex-1 h-40 border border-hrColor p-4 rounded-lg">
            <div className="flex justify-center text-[17px] sm:text-[22px] font-bold mt-2">
              {userProfile.rank ? userProfile.rank : "- "}위
              <span className="text-[11px] mt-2 sm:text-[15px] ml-1 sm:mt-2.5 font-normal">
                / {userProfile.totalUserCount}위
              </span>
              {userProfile.rankChange === 0 ? (
                <></>
              ) : userProfile.rankChange > 0 ? (
                <p className="ml-1 mt-2.5 text-[15px] items-center text-green-500">
                  ▲{userProfile.rankChange}
                </p>
              ) : (
                <p className="ml-1 mt-2.5 text-[15px] items-center text-red-500">
                  ▼{userProfile.rankChange}
                </p>
              )}
            </div>
            <p className="text-thirdText text-center text-[11px]">
              {moment().subtract(1, "days").format("YYYY-MM-DD")} 일자 기준
            </p>
            <hr className="my-4 border border-hrColor" />
            <Link to="/users" className="text-blue-400 text-[12px]">
              전체 순위 보러가기
            </Link>
          </div>
        </div>
      </div>

      {/* Top 3 Section */}
      <div className="mt-7 mb-3">TOP 3 Q&A</div>
      <div className="border border-hrColor rounded-lg">
        <ul>
          {userProfile.top3Contents.length === 0 && (
            <div className="flex justify-center items-center h-16 text-thirdText ">
              아직 작성한 Q&A가 없습니다.
            </div>
          )}
          {userProfile.top3Contents.map((top3Content, index) => (
            <li
              key={index}
              className={`flex justify-between items-center space-x-4 p-3 ${
                index === userProfile.top3Contents.length - 1
                  ? ""
                  : "border-b border-hrColor"
              }`}
            >
              <Link
                to={`/qna/${top3Content.questionId}/${cleanAndReplaceSpaces(
                  top3Content.title
                )}`}
              >
                <div className="flex items-center w-full">
                  <span className="inline-flex items-center mr-2 py-0.5 px-1.5 max-h-8 border rounded-lg border-starBorder">
                    <FaStar className="text-yellow-500 h-3 w-3" />
                    <span className="ml-1 text-yellow-500 text-[12px]">
                      {top3Content.voteCount}
                    </span>
                  </span>
                  <p className="text-linkColor text-[13px] w-90p items-center ">
                    {stripHtmlTags(top3Content.title, 120)}
                  </p>
                </div>
              </Link>
              <p className="text-secondText items-center flex justify-end text-[12px] w-30p">
                {top3Content.date}
              </p>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-7 mb-3">TOP 3 기술 노트</div>
      <div className="border border-hrColor rounded-lg">
        <ul>
          {userProfile.top3TechNote.length === 0 && (
            <div className="flex justify-center items-center h-16 text-thirdText ">
              아직 정리한 기술 노트가 없습니다.
            </div>
          )}
          {userProfile.top3TechNote.map((top3Content, index) => (
            <li
              key={index}
              className={`flex justify-between items-center space-x-4 p-3 ${
                index === userProfile.top3TechNote.length - 1
                  ? ""
                  : "border-b border-hrColor"
              }`}
            >
              <Link
                to={`/technote/${
                  top3Content.questionId
                }/${cleanAndReplaceSpaces(top3Content.title)}`}
              >
                <div className="flex items-center w-full">
                  <span className="inline-flex items-center mr-2 py-0.5 px-1.5 max-h-8 border rounded-lg border-starBorder">
                    <FaStar className="text-yellow-500 h-3 w-3" />
                    <span className="ml-1 text-yellow-500 text-[12px]">
                      {top3Content.voteCount}
                    </span>
                  </span>
                  <p className="text-linkColor text-[13px] w-90p items-center ">
                    {stripHtmlTags(top3Content.title, 120)}
                  </p>
                </div>
              </Link>
              <p className="text-secondText items-center flex justify-end text-[12px] w-30p">
                {top3Content.date}
              </p>
            </li>
          ))}
        </ul>
      </div>

      {/* Ranking and Activity Section */}
      <div className="w-full mt-7">
        <div className="mb-3">Activity</div>
        <div className="flex justify-center h-40 border border-hrColor items-center rounded-lg">
          <p className="text-[15px] sm:text-2xl text-center mr-5p font-bold">
            총 {userProfile.totalExperience}xp
          </p>
          <div className="flex items-center text-secondText text-[10px] sm:text-[13px] space-x-7">
            <div className="border-l w-33p border-hrColor px-2">
              받은 <div>투표 수</div> <div>{userProfile.voteCount}</div>
            </div>
            <div className="border-l w-33p border-hrColor px-2">
              작성한 <div>Q&A 수</div>
              <div>{userProfile.questionCount + userProfile.answerCount}</div>
            </div>
            <div className="border-l w-33p border-hrColor px-2">
              작성한 <div>기술노트 수</div>
              <div>{userProfile.techNoteCount}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 text-thirdText text-[11px] text-start mb-20">
        shot out to Korea developer <br />@
        <span className="font-JockeyOne mr-1">understand</span>
      </div>
    </>
  );
};

export default DashboardTab;
