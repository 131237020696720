import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Sidebar, RightSidebar, Navbar } from "@components";
import {
  AnnouncementDetailPage,
  AnnouncementPage,
  AskQuestionPage,
  QnADetailPage,
  QnAListPage,
  UsersPage,
  UserPage,
  UpdateQuestionPage,
  SearchQnAPage,
  QnATagPage,
  ProfileSettingPage,
  NotFoundErrorPage,
  AboutPage,
  TechNoteListPage,
  WriteTechNotePage,
  TechNoteDetailPage,
  UpdateTechNotePage,
} from "@pages";
import { AuthProvider } from "@contexts/AuthContext";
import ogLogo from "@assets/ogLogo.png";
import { LoginModal } from "@components/modal";
import BottomNavbar from "@components/common/bottomNavbar";
import NotificationPage from "@pages/NotificationPage";

const App = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [hasLogged, setHasLogged] = useState(false); // 로그 상태를 관리하는 상태 변수

  useEffect(() => {
    if (!hasLogged) {
      console.log("============ ** 개발자 이벤트 ** =========== ");
      console.log(
        `이 로그를 보신 분들께만(**선착순 10명** 유저수도 얼마 없어요 여기) 
        \nunderstandevcom@gmail.com 메일로 \n understand의 아이디와 서비스에 대한 피드백을 보내주시면.
        \n스타벅스 기프티콘 보내드립니다. (꼭 내용 누락 없이 부탁드려요)
        \n개발자님의 소중한 피드백은 서비스를 개선하는데 큰 힘이 됩니다!!!!!`
      );
      console.log("=========================================");
      setHasLogged(true); // 로그를 한 번 출력한 후 상태를 true로 변경
    }
  }, [hasLogged]);

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isSidebarOpen]);

  return (
    <Router>
      <AuthProvider>
        <HelmetProvider>
          <Helmet prioritizeSeoTags>
            <title>understand</title>
            <meta
              name="description"
              content="전문성을 키울 수 있는 한국 개발자의 네트워크"
            />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="understandev" />
            <meta
              property="og:description"
              content="전문성을 키울 수 있는 한국 개발자의 네트워크"
            />
            <meta property="og:image" content={ogLogo} />
            <meta property="og:url" content={window.location.href} />
          </Helmet>

          {/* <div className="grid grid-cols-1 md:grid-cols-[70%_30%] lg:grid-cols-[10%_14%_50%_19%_10%] 2xl:grid-cols-[17%_12%_1fr_15%_17%] h-screen">
            <div className="hidden md:block" />
            <div className="">
              <div className="hidden lg:block">
                <Sidebar openLoginModal={openLoginModal} />
              </div>
            </div>
            <div className="flex w-full flex-col min-h-screen border-l border-r border-hrColor">
              <Navbar />
              <div className="flex-grow overflow-y-auto pb-[env(safe-area-inset-bottom)]">
                <AppRoutes />
                <div className="block lg:hidden absolute bottom-0 left-0 w-full pb-[env(safe-area-inset-bottom)]">
                  <BottomNavbar />
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <RightSidebar openLoginModal={openLoginModal} />
            </div>
            <div className="hidden md:block" />
          </div> */}

          <div>
            {/* 상단에 고정된 Navbar */}
            <div className="fixed top-0 left-0 w-full z-10">
              <Navbar />
            </div>

            {/* 컨텐츠 영역 */}
            <div className="flex md:h-screen pt-16 pb-20 md:pb-0">
              {/* 패딩을 추가하여 Navbar와 BottomNavbar를 고려 */}
              <div className="w-430 hidden md:block">
                <Sidebar openLoginModal={openLoginModal} />
              </div>
              <div className="w-full flex  md:border-l lg:border-r border-hrColor">
                <div className="flex-grow overflow-y-auto">
                  <AppRoutes />
                </div>
              </div>
              <div className="w-570 hidden lg:block">
                <RightSidebar />
              </div>
            </div>

            {/* 하단에 고정된 BottomNavbar (sm 이하일 때만) */}
            <div className="block md:hidden fixed bottom-0 left-0 w-full z-10">
              <BottomNavbar />
            </div>
          </div>

          {isLoginModalOpen && (
            <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
          )}
        </HelmetProvider>
      </AuthProvider>
    </Router>
  );
};

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/qna" />} />
    <Route path="/ask" element={<AskQuestionPage />} />
    <Route path="/qna" element={<QnAListPage />} />
    <Route path="/qna/:id/:title" element={<QnADetailPage />} />
    <Route path="/qna/:id" element={<QnADetailPage />} />
    <Route path="/qna/:id/edit" element={<UpdateQuestionPage />} />
    <Route path="/qna/tag/:tag" element={<QnATagPage />} />
    <Route path="/search" element={<SearchQnAPage />} />
    <Route path="/announcements" element={<AnnouncementPage />} />
    <Route path="/announcements/:id" element={<AnnouncementDetailPage />} />
    <Route path="/users" element={<UsersPage />} />
    <Route path="/users/:id" element={<UserPage />} />
    <Route path="/users/:id/:nickname" element={<UserPage />} />
    <Route path="/users/:id/edit" element={<ProfileSettingPage />} />
    <Route path="/about" element={<AboutPage />} />
    <Route path="/technote" element={<TechNoteListPage />} />
    <Route path="/technote/:id/:title" element={<TechNoteDetailPage />} />
    <Route path="/technote/:id" element={<TechNoteDetailPage />} />
    <Route path="/technote/create" element={<WriteTechNotePage />} />
    <Route path="/technote/:id/edit" element={<UpdateTechNotePage />} />
    <Route path="/notification" element={<NotificationPage />} />
    <Route path="/*" element={<NotFoundErrorPage />} />
  </Routes>
);

export default App;
