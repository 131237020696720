import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { FaCaretRight } from "react-icons/fa6";
import { FaCaretDown } from "react-icons/fa6";
import { sendPasswordAuthEmail, verifyResetCode } from "@api/api";
import { useAuth } from "@contexts";
import SignupSuccessModal from "./SignupSuccessModal";

const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [authType, setAuthType] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [timer, setTimer] = useState(600);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [result, setResult] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);

  const { login } = useAuth();

  useEffect(() => {
    let interval;
    if (isCodeSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCodeSent, timer]);

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true); // 로딩 상태 시작

    const response = await sendPasswordAuthEmail(email);
    setAuthType(response.type);

    setIsCodeSent(true);
    setTimer(600); // 타이머 리셋
    setIsLoading(false); // 로딩 상태 종료
  };

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setIsLoading(true); // 로딩 상태 시작

    if (!isCorrect && authType === "signup") {
      alert("개발 문제를 풀어주세요!");
      setIsLoading(false); // 로딩 상태 종료
    } else {
      setError("");
      try {
        const response = await verifyResetCode(email, verificationCode);

        if (response) {
          await login();
          if (response.signup) {
            setUser(response.user);
            setIsSignupModalOpen(true);
          } else {
            onClose();
            window.location.reload();
          }
        } else {
          setError("인증 번호가 올바르지 않습니다.");
        }
      } catch (error) {
        setError("인증 번호 확인에 실패했습니다.");
      } finally {
        setIsLoading(false); // 로딩 상태 종료
      }
    }
  };

  const isEmailButtonDisabled = !email || isLoading;
  const isVerificationButtonDisabled =
    verificationCode.length !== 5 || timer <= 0 || isLoading;

  if (!isOpen) return null;

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const closeSignupModal = () => {
    setIsSignupModalOpen(false);
    onClose();
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const checkResult = () => {
    if (result === "13") {
      alert("정답입니다!");
      setIsCorrect(true);
    } else {
      alert("정답이 아닙니다.");
    }
  };

  return (
    <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50">
      {isSignupModalOpen ? (
        <div></div>
      ) : (
        <div className="bg-modalBg p-12 rounded-lg shadow-lg w-96 md:w-390 relative">
          <button
            className="absolute top-5 right-5 text-secondText hover:text-gray-700"
            onClick={onClose}
          >
            <IoClose className="w-7 h-7 hover:text-white" />
          </button>

          {authType === "" && (
            <h2 className="text-xl font-bold mb-5">로그인 / 회원가입</h2>
          )}
          {authType === "login" && (
            <h2 className="text-xl font-bold mb-5">로그인</h2>
          )}
          {authType === "signup" && (
            <div>
              <span className="text-[24px] font-bold">회원가입</span>
              <p className="text-[17px] font-bold mb-2">
                <span className="font-JockeyOne">understand</span> 에 오신
                개발자님 환영합니다.
              </p>
              <p className="text-[12px] text-secondText mb-5">
                "회원가입"을 클릭하시면
                <a
                  href="/announcements/1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-linkColor w-full"
                >
                  서비스 약관
                </a>{" "}
                및
                <a
                  href="/announcements/2"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-linkColor w-full"
                >
                  개인정보 보호정책 약관
                </a>
                에 동의하게 됩니다.
              </p>
            </div>
          )}

          <form onSubmit={handleSendEmail}>
            <div className="mb-4">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="이메일을 입력하세요"
                className={`w-full px-3 py-3 bg-modalInput rounded-lg focus:outline-none ${
                  isCodeSent ? "text-secondText cursor-not-allowed" : ""
                }`}
                disabled={isCodeSent} // 인증 메일을 받은 경우 비활성화
              />
            </div>
            <div className="flex items-center justify-between mt-3">
              {!isCodeSent && (
                <button
                  type="submit"
                  className={`font-bold py-2 w-full rounded-lg mt-2 focus:outline-none focus:shadow-outline ${
                    isEmailButtonDisabled
                      ? "bg-modalInput text-disableTextColor cursor-not-allowed"
                      : "bg-pointColor"
                  }`}
                  disabled={isEmailButtonDisabled}
                >
                  {isLoading ? "전송 중..." : "인증 메일 받기"}
                </button>
              )}
            </div>
          </form>
          {isCodeSent && (
            <>
              <div>
                <div className="text-secondText text-sm">
                  남은 시간: {formatTime(timer)}
                </div>
                <input
                  type="text"
                  id="verificationCode"
                  value={verificationCode}
                  onChange={handleVerificationCodeChange}
                  className="w-full px-3 py-3 mt-2 bg-modalInput rounded-lg focus:outline-none"
                  placeholder="인증번호를 입력하세요"
                />
                <div className="mt-2">
                  <span className="text-red-500 text-[12px]">
                    * 인증번호가 오지 않았다면 스팸메일함을 확인해주세요
                  </span>
                </div>
              </div>
              {authType === "signup" && (
                <div className="mt-3">
                  {!isVisible ? (
                    <button
                      onClick={toggleVisibility}
                      className="flex items-center py-2 rounded-lg"
                    >
                      <FaCaretRight className="h-5 w-5 mr-1" />
                      개발자임을 확인하기 위한 문제를 풀어주세요
                    </button>
                  ) : (
                    <button
                      onClick={toggleVisibility}
                      className="flex items-center py-2 rounded-lg"
                    >
                      <FaCaretDown className="h-5 w-5 mr-1" />
                      문제 보기
                    </button>
                  )}
                  {isVisible && (
                    <div>
                      <span className="text-[13px] text-thirdText">
                        언더스탠은 오직 개발자만을 위한 공간입니다.
                      </span>
                      <div className="bg-modalInput py-3 px-5 rounded-lg mt-1">
                        <div>
                          <span className="text-blue-500 mr-2">def</span>
                          <span className="text-red-500">fibonacci</span>(n):
                        </div>
                        <div className="ml-10">
                          <span className="text-blue-500 mr-2">if</span>n &lt;=
                          1:
                        </div>
                        <div className="ml-20">
                          <span className="text-blue-500 mr-2">return</span> n
                        </div>
                        <div className="ml-10 text-blue-500">else:</div>
                        <div className="ml-20">
                          <span className="text-blue-500 mr-2">return</span>
                          fibonacci(n - 1) + fibonacci(n - 2)
                        </div>
                      </div>
                      <div className="mt-3 text-[13px]">
                        fibonacci(7)의 결과는?
                      </div>
                      <div className="flex mt-2">
                        <input
                          type="text"
                          id="verificationCode"
                          value={result}
                          onChange={(e) => setResult(e.target.value)}
                          className="px-3 py-2.5 w-full bg-modalInput rounded-lg focus:outline-none"
                          placeholder="정답을 입력해주세요"
                        />
                        {isCorrect ? (
                          <button
                            onClick={checkResult}
                            className="ml-3 px-2 w-32 bg-pointColor text-[13px] rounded-lg"
                          >
                            완료
                          </button>
                        ) : (
                          <button
                            onClick={checkResult}
                            className="ml-3 px-2 w-32 bg-pointColor text-[13px] rounded-lg"
                          >
                            정답 확인
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {error && <p className="text-red-500 mt-3">{error}</p>}

              <div className="flex items-center justify-between mt-5">
                <button
                  onClick={handleVerifyCode}
                  className={`font-bold py-2 w-full rounded-lg focus:outline-none focus:shadow-outline ${
                    isVerificationButtonDisabled
                      ? "bg-black text-disableTextColor cursor-not-allowed"
                      : "bg-pointColor"
                  }`}
                  disabled={isVerificationButtonDisabled}
                >
                  {isLoading
                    ? "로딩 중..."
                    : authType === "login"
                    ? "로그인"
                    : "회원가입"}
                </button>
              </div>
            </>
          )}
        </div>
      )}
      {isSignupModalOpen && (
        <SignupSuccessModal onClose={closeSignupModal} user={user} />
      )}
    </div>
  );
};

export default LoginModal;
