import { getUserProfile } from "@api/api";
import React, { useEffect, useState } from "react";
import { FaGithub, FaLink, FaBookOpen } from "react-icons/fa";
import { HiCircleStack } from "react-icons/hi2";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { IoSettingsOutline, IoBusiness } from "react-icons/io5";
import LoadingPage from "./LoadingPage";
import { stripHtmlTags } from "@utils/utils";
import ServerErrorPage from "./error/ServerErrorPage";
import UserProfileImage from "@components/common/UserProfileImage";
import DashboardTab from "@components/layout/DashboardTab";
import { ActivityTab } from "@components/layout";
import { useAuth } from "@contexts/AuthContext";
import ProfileStarButton from "@components/common/ProfileStarButton";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const UserPage = () => {
  const { id, nickname } = useParams();
  const [userProfile, setUserProfile] = useState(null);
  const [hasServerError, setHasServerError] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const activeTab = query.get("tab") || "dashboard";
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // useEffect(() => {
  //   if (!query.get("tab")) {
  //     navigate(`${location.pathname}?tab=dashboard`);
  //   }
  // }, [query, navigate, location]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile(id);
        setUserProfile(userProfile);
        setHasServerError(false);

        // let path = location.pathname;
        // if (!nickname) {
        //   path += `/${cleanAndReplaceSpaces(userProfile.nickname)}`;
        // }

        // console.log("query.get()", query.get("tab"));
        // if (!query.get("tab")) {
        //   path += "?tab=dashboard";
        // }

        // navigate(path);
      } catch (e) {
        console.error("유저 데이터를 불러오는데 실패했습니다.", e);
        setHasServerError(true);
      }
    };

    fetchUserProfile();
  }, [id, nickname]);

  const renderContent = () => {
    switch (activeTab) {
      case "dashboard":
        return <DashboardTab userProfile={userProfile} />;
      case "questions":
      case "answers":
      case "technotes":
        return <ActivityTab activeTab={activeTab} />;
      default:
        return <DashboardTab userProfile={userProfile} />;
    }
  };

  const handleTabClick = (tab) => {
    navigate(`${location.pathname}?tab=${tab}`);
  };

  if (hasServerError) return <ServerErrorPage />;

  if (!userProfile) return <LoadingPage />;

  return (
    <>
      <Helmet>
        <title>{`${userProfile.nickname}님의 프로필`}</title>
        <meta
          name="description"
          content={`${userProfile.nickname}님의 프로필입니다.`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`understandev / ${userProfile.nickname}`}
        />
        <meta
          property="og:description"
          content={`${userProfile.nickname}님의 프로필입니다.`}
        />
        <meta
          property="og:image"
          content={
            userProfile.profilePicture ||
            process.env.REACT_APP_DEFAULT_PROFILE_URL
          }
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="mb-10">
        <div className="bg-textColor text-black mb-10">
          <h1 className="font-JockeyOne text-[45px] w-full py-10 text-center">
            understand
          </h1>
        </div>
        <div className="px-10p">
          {/* Profile Header */}
          <div className="flex items-center justify-between space-x-4">
            <div className="relative flex items-center">
              <UserProfileImage
                profilePicture={userProfile.profilePicture}
                imageStyle={
                  "w-20 h-20 sm:w-24 sm:h-24 rounded-full object-cover relative "
                }
              />
              <div className="text-[13px] font-bold flex justify-center bg-searchColor rounded-full px-2.5 py-0.5 absolute top-20 sm:top-24 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-5">
                Lv.{userProfile.level}
              </div>
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex items-center justify-between w-full">
                <div className="flex">
                  <span className="text-[20px] sm:text-[24px] font-bold mr-2">
                    {userProfile.nickname}
                  </span>
                  <div>
                    <ProfileStarButton
                      userId={id}
                      initialVoteCount={userProfile.profileVoteCount}
                    />
                  </div>
                </div>
                {isLoggedIn && user && user.id === userProfile.userId && (
                  <Link to={`/users/${user.id}/edit`} className="mr-2">
                    <button className="text-secondText hover:text-linkColor">
                      <IoSettingsOutline className="w-6 h-6 items-center" />
                    </button>
                  </Link>
                )}
              </div>
              <p className="text-secondText text-[11px] sm:text-[13px]">
                <span className="font-JockeyOne text-[15px] mr-1">
                  understand
                </span>
                에서 활동한지 {userProfile.daysSinceJoined}일째 되는 날입니다
              </p>
            </div>
          </div>
          {/* About Section */}
          <div className="mt-12">
            <h2 className="text-lg font-bold mb-1">About</h2>
            <p className="placeholder-thirdText">{userProfile.about}</p>
            {(!userProfile.about || userProfile.about === "") && (
              <p className="text-thirdText ">등록된 소개가 없습니다.</p>
            )}
          </div>
          {/* Links Section */}
          <div className="mt-8 text-[13px]">
            <h2 className="text-lg font-bold mb-2">Link</h2>
            <ul className="space-y-2">
              <div className="flex">
                <li className="flex items-center">
                  <FaGithub className="w-5 h-5 mr-2" />
                  {userProfile.githubUrl ? (
                    <a
                      href={userProfile.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-linkColor w-full"
                    >
                      {stripHtmlTags(userProfile.githubUrl, 80)}
                    </a>
                  ) : (
                    <p className="text-thirdText">
                      등록된 gitHub 주소가 없습니다.
                    </p>
                  )}
                </li>
              </div>
              {userProfile.websiteUrl && (
                <div className="flex">
                  <li className="flex items-center">
                    <FaLink className="w-5 h-5 mr-2" />
                    <a
                      href={userProfile.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-linkColor w-full"
                    >
                      {stripHtmlTags(userProfile.websiteUrl, 100)}
                    </a>
                  </li>
                </div>
              )}
            </ul>
          </div>

          <div className="mt-8 text-[13px]">
            <h2 className="text-lg font-bold mb-2">Book</h2>
            <ul className="space-y-2">
              <div className="flex">
                <li className="flex items-center">
                  <FaBookOpen className="w-5 h-5 mr-2" />
                  {userProfile.book ? (
                    stripHtmlTags(userProfile.book, 100)
                  ) : (
                    <p className="text-thirdText">
                      최근 읽은 개발 서적이 없습니다.
                    </p>
                  )}
                </li>
              </div>
            </ul>
          </div>

          {userProfile.company && (
            <div className="mt-8 text-[13px]">
              <h2 className="text-lg font-bold mb-2">Company</h2>
              <ul className="space-y-2">
                <div className="flex">
                  <li className="flex items-center">
                    <IoBusiness className="w-5 h-5 mr-2" />
                    {userProfile.company}
                  </li>
                </div>
              </ul>
            </div>
          )}
          <div className="mt-8 text-[13px]">
            <h2 className="text-lg font-bold mb-2">TechStack</h2>
            <ul className="space-y-2">
              <div className="flex">
                <li className="flex items-center">
                  <HiCircleStack className="w-6 h-5 mr-1" />
                  {userProfile.techStack ? (
                    <p>{userProfile.techStack}</p>
                  ) : (
                    <p className="text-thirdText">
                      등록된 기술 스택이 없습니다.
                    </p>
                  )}
                </li>
              </div>
            </ul>
          </div>
          <div className="flex space-x-1.5 mt-12 mb-3 text-[12px]">
            <button
              onClick={() => handleTabClick("dashboard")}
              className={`w-auto px-3 py-1.5 rounded-full hover:bg-pointColor ${
                activeTab === "dashboard" ? "bg-pointColor" : ""
              }`}
            >
              대시보드
            </button>
            <button
              onClick={() => handleTabClick("questions")}
              className={`px-2.5 py-1.5 rounded-full hover:bg-pointColor ${
                ["activity", "questions", "answers", "technotes"].includes(
                  activeTab
                )
                  ? "bg-pointColor"
                  : ""
              }`}
            >
              활동기록
            </button>
          </div>
          <hr className="mb-2 border border-hrColor" />
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default UserPage;
