import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsFillPinAngleFill } from "react-icons/bs";
import { getAnnouncements } from "@api/api";
import { Pagination } from "@components";
import { LoadingPage, ServerErrorPage } from "@pages";
import { stripHtmlTags } from "@utils/utils";

const AnnouncementPage = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [AnnouncementsPerPage] = useState(10);
  const [hasServerError, setHasServerError] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await getAnnouncements(
          currentPage,
          AnnouncementsPerPage
        );
        setAnnouncements(response.announcements);
        setTotalCount(response.totalCount);
        setHasServerError(false);
      } catch (error) {
        console.error("공지사항 데이터를 불러오는데 실패했습니다.", error);
        setHasServerError(true);
      }
    };
    fetchAnnouncements();
  }, [currentPage, AnnouncementsPerPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (hasServerError) return <ServerErrorPage />;

  if (!announcements) return <LoadingPage />;

  return (
    <div className="flex mb-10">
      <div className="w-full h-full">
        <div className="flex justify-between p-8">
          <div className="text-[24px] font-medium">공지사항</div>
          <div className="text-[13px] mt-3">총 {totalCount}개</div>
        </div>
        <div className="grid w-auto">
          {announcements.map((announcement) => (
            <div key={announcement.id} className="flex px-6 py-2.5 md:px-8">
              <div className="flex border border-hrColor py-2.5 px-4 rounded-xl">
                <Link to={`/announcements/${announcement.id}`}>
                  <div className="flex items-center">
                    {announcement.isPinned && (
                      <BsFillPinAngleFill className="text-white items-center mr-2 w-5 h-5" />
                    )}
                    <h2 className="text-[16px] font-bold mb-1 hover:text-linkColor">
                      {announcement.title}
                    </h2>
                  </div>
                  <p className="text-[13px]">
                    {stripHtmlTags(announcement.body)}
                  </p>
                  <div className="flex justify-between mt-2 text-secondText">
                    <div className="text-[11px]">
                      게시일. {announcement.createdAt}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
          <Pagination
            itemsPerPage={AnnouncementsPerPage}
            totalItems={totalCount}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementPage;
