import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination } from "react-table";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FaBolt } from "react-icons/fa6";
import moment from "moment";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { getRankingList } from "@api/api";
import ServerErrorPage from "./error/ServerErrorPage";
import UserProfileImage from "@components/common/UserProfileImage";
import { cleanAndReplaceSpaces } from "@utils/utils";

const UsersPage = () => {
  const [rankingList, setRankingList] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [hasServerError, setHasServerError] = useState(false);
  const [RankingPerPage] = useState(20);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentPage = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    if (!queryParams.has("page")) {
      navigate("?page=1", { replace: true });
    }
  }, [queryParams, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const paginate = (pageNumber) => {
    navigate(`?page=${pageNumber}`);
  };

  useEffect(() => {
    const fetchRankingList = async () => {
      try {
        const response = await getRankingList(currentPage, RankingPerPage);
        setRankingList(response.rankingList);
        setTotalCount(response.totalCount);
        setHasServerError(false);
      } catch (error) {
        console.error("유저 리스트 데이터를 불러오는데 실패했습니다.", error);
        setHasServerError(true);
      }
    };
    fetchRankingList();
  }, [currentPage, RankingPerPage]);

  const columns = useMemo(
    () => [
      {
        Header: "순위",
        accessor: "rank",
        Cell: ({ cell: { value } }) => (
          <span className={`mr-1 ${value ? "" : "text-thirdText"}`}>
            {value ? `${value}` : "-"}
            {value ? <span className="text-[12px]">위</span> : ""}
          </span>
        ),
      },
      {
        Header: "닉네임",
        accessor: "nickname",
        Cell: ({ cell: { value }, row: { original } }) => (
          <div className="flex items-center">
            <UserProfileImage
              profilePicture={original.profilePicture}
              imageStyle={"w-6 h-6 rounded-full mr-2"}
            />
            {original.rank === 1 && (
              <FaBolt className="h-4 w-4 mr-1 text-purple" />
            )}
            <span
              className={`mr-1 ${
                original.rank === 1 ? "text-purple font-bold" : ""
              }`}
            >
              {value}
            </span>
            <span className="text-secondText hidden sm:table-cell">
              #{original.levelName}
            </span>
          </div>
        ),
      },
      {
        Header: "추천 수",
        accessor: "voteCount",
        Cell: ({ cell: { value } }) => (
          <span
            className={`${
              value === 0 ? " text-thirdText" : " text-textColor"
            }  `}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "레벨",
        accessor: "level",
        Cell: ({ cell: { value }, row: { original } }) => (
          <span>LV.{value}</span>
        ),
      },
      {
        Header: "경험치",
        accessor: "totalExperience",
        Cell: ({ cell: { value }, row: { original } }) => (
          <span>{value}xp</span>
        ),
      },
      // {
      //   Header: "활동일자",
      //   accessor: "daysSinceJoined",
      //   Cell: ({ cell: { value }, row: { original } }) => (
      //     <span>{value}일</span>
      //   ),
      //   className: "hidden sm:table-cell",
      // },
      {
        Header: "랭킹 변화",
        accessor: "rankChange",
        Cell: ({ cell: { value } }) => (
          <span
            style={{ color: value > 0 ? "green" : value < 0 ? "red" : "gray" }}
          >
            {value > 0 ? "▲" : value < 0 ? "▼" : "-"} {Math.abs(value)}
          </span>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => rankingList, [rankingList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    state: { pageIndex = currentPage },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage - 1, pageSize: RankingPerPage },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / RankingPerPage),
    },
    usePagination
  );

  // useEffect(() => {
  //   setCurrentPage(pageIndex + 1);
  // }, [pageIndex]);
  const pageDown = () => {
    previousPage();
    console.log("page", pageIndex);
    paginate(pageIndex + 1 - 1);
  };

  const pageUp = () => {
    nextPage();
    console.log("page", pageIndex);
    paginate(pageIndex + 1 + 1);
  };

  if (hasServerError) return <ServerErrorPage />;

  return (
    <>
      <Helmet>
        <title>developers</title>
      </Helmet>
      <div className=" w-full flex flex-col mb-10">
        <div className="flex justify-center">
          <div className="w-full">
            <div className="px-8 py-6">
              <div className="text-[24px] font-medium">RANKING</div>
              <div className="mt-2 text-[12px] sm:text-[13px] sm:flex sm:justify-between">
                <div>
                  <span className="font-JockeyOne text-[15px]">understand</span>{" "}
                  에 함께 성장하는 개발자들을 소개합니다.
                </div>
                {/* <div className="text-[13px]">총 {totalCount}명</div> */}
              </div>
            </div>

            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-hrColor"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className={`px-4 py-3 text-[9px] sm:text-[12px] font-medium text-secondText border-t border-b border-hrColor uppercase tracking-wider ${
                          column.Header === "닉네임"
                            ? "text-left"
                            : "text-center"
                        } ${column.className || ""}`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="border-b border-hrColor hover:bg-teest"
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className={`px-2 sm:px-4 py-3 text-center text-[12px] sm:text-[13px] ${
                            cell.column.className || ""
                          }`}
                        >
                          <Link
                            to={`/users/${
                              row.original.userId
                            }/${cleanAndReplaceSpaces(
                              row.original.nickname
                            )}?tab=dashboard`}
                          >
                            {cell.render("Cell")}
                          </Link>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="mt-3 text-[11px] px-5 text-secondText">
              * 첫 활동을 한 경우에만 랭킹이 부여됩니다.
            </div>
            <div className="mt-1 text-[11px] text-secondText px-5">
              * 순위는 매일 자정에 업데이트 됩니다. ( 현재{" "}
              {moment().subtract(1, "days").format("YYYY-MM-DD")} 일자 기준)
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={pageDown}
                disabled={!canPreviousPage}
                className={`px-4 py-3 ml-2 rounded-lg ${
                  canPreviousPage
                    ? "text-textColor bg-modalBg hover:bg-searchColor"
                    : "text-disableTextColor"
                }`}
              >
                <FaChevronLeft className="w-5 h-5" />
              </button>
              <button
                onClick={pageUp}
                disabled={!canNextPage}
                className={`px-4 py-3 mr-2 rounded-lg ${
                  canNextPage
                    ? "text-textColor bg-modalBg hover:bg-searchColor"
                    : "text-disableTextColor"
                }`}
              >
                <FaChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersPage;
