import React from "react";

const ProfileImageModal = ({
  isOpen,
  onClose,
  onImageChange,
  onDeleteImage,
}) => {
  if (!isOpen) return null;

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      onImageChange(e.target.files[0]);
    }
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-modalBg text-white pt-8 rounded-lg shadow-lg sm:w-96">
        <h2 className="text-xl mb-8 text-center">프로필 사진 바꾸기</h2>
        <div className="border-b border-t border-gray-600">
          <label
            htmlFor="file-upload"
            className="block py-3 text-center text-blue-500 cursor-pointer hover:bg-gray-800"
          >
            사진 업로드
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
        <div className="border-b border-gray-600">
          <button
            onClick={() => {
              onDeleteImage();
              onClose();
            }}
            className="block w-full py-3 text-center text-red-500 hover:bg-gray-800"
          >
            현재 사진 삭제
          </button>
        </div>
        <div>
          <button
            onClick={onClose}
            className="block w-full py-3 text-center hover:bg-gray-800"
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageModal;
